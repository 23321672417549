import commonAction from 'actions/commonAction';
import TaskFlowsApi from 'api/generated/TaskFlowsApi';

export const REOPEN_BENEFITS_SELECTION_ACTION = 'REOPEN_BENEFITS_SELECTION';

export const reopenBenefitsSelection = (userId: string) =>
    commonAction(
        async () => new TaskFlowsApi().reopenBenefitsSelection(userId),
        REOPEN_BENEFITS_SELECTION_ACTION,
        {
            additionalDispatchData: { userId },
            toastErrorMessage: 'Failed to re-open benefits selection',
            toastSuccessMessage: 'Successfully re-opened benefits selection',
        }
    );
