import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_REIMBURSEMENT_REPORT_ACTION = 'GET_REIMBURSEMENT_REPORT';

export const getReimbursementReport = (reimbursementReportId: string) =>
    commonAction(
        async () => new SubmittedExpensesApi().getReimbursementReport(reimbursementReportId),
        GET_REIMBURSEMENT_REPORT_ACTION
    );
