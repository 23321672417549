import { CancelToken } from 'axios';
import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';
import { IPeopleState } from 'pages/people/peopleState';

export const LIST_PAGED_USER_PROFILES_ACTION = 'LIST_PAGED_USER_PROFILES_ACTION';

export const listPagedUserProfiles = (
    teamId: string,
    year: number,
    paginationParams: IPeopleState,
    token?: CancelToken
) => {
    const {
        enrollmentStatusFilters,
        orderings,
        page,
        pageSize,
        search,
        userInfoStatusFilters,
        userStatusFilters,
    } = paginationParams;
    return commonAction(
        async () =>
            new UsersApi().pageProfiles(
                teamId,
                year,
                userStatusFilters,
                enrollmentStatusFilters,
                userInfoStatusFilters,
                page,
                pageSize,
                search,
                orderings,
                token
            ),
        LIST_PAGED_USER_PROFILES_ACTION
    );
};
