import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const SET_ACTIVE_PATHWAY_BLUEPRINT = 'SET_ACTIVE_PATHWAY_BLUEPRINT';

export const setActivePathwayBlueprint = (pathwayBlueprintId: string) =>
    commonAction(
        async () => new PathwayBlueprintApi().setActivePathwayBlueprint(pathwayBlueprintId),
        SET_ACTIVE_PATHWAY_BLUEPRINT,
        {
            additionalDispatchData: {
                params: {
                    pathwayBlueprintId,
                },
            },
            toastErrorMessage: (response) =>
                `Unable to set Pathway Blueprint as active. ${response.error.response?.data}`,
            toastSuccessMessage: 'Pathway Blueprint set as active',
        }
    );
