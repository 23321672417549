import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const GET_CURRENT_USER_BUDGETS_FOR_TEAM_ACTION = 'GET_CURRENT_USER_BUDGETS_FOR_TEAM';

export const getUserBudgetsForTeam = (teamId: string, year?: number) =>
    commonAction(
        async () => new TeamsApi().getBudgets(teamId, year),
        GET_CURRENT_USER_BUDGETS_FOR_TEAM_ACTION
    );
