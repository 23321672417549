import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const DELETE_HOUSEHOLD_MEMBER_YEAR_DATA_ACTION = 'DELETE_HOUSEHOLD_MEMBER_YEAR_DATA';

export const deleteHouseholdMemberYearData = (
    householdMemberId: string | undefined,
    year: number | undefined
) =>
    commonAction(
        async () => new UsersApi().deleteHouseholdMemberYearData(householdMemberId, year),
        DELETE_HOUSEHOLD_MEMBER_YEAR_DATA_ACTION,
        {
            additionalDispatchData: { householdMemberId },
            toastErrorMessage: 'Unable to remove the household member',
        }
    );
