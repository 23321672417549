import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';
import { PlanDetailsAndRatesRequest } from 'api/generated/models';

export const GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION = 'GET_MARKETPLACE_PLAN_RATES_FOR_PLANS';
export const GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION =
    'GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER';

export const getMarketplacePlanRatesForPlans = (
    userId: string | undefined,
    planIds: string[],
    excludingMembers: string[] | undefined,
    year: number,
    isOffExchange: boolean,
    isRefresh = true,
    overrideNeedsCoverage = false,
    actionType = GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION
) =>
    commonAction(
        async () =>
            new MarketplaceApi().listDetailsForPlansForHousehold(
                userId,
                new PlanDetailsAndRatesRequest({
                    excludingMembers,
                    isOffExchange,
                    overrideNeedsCoverage,
                    planIds,
                    year,
                })
            ),
        actionType,
        {
            additionalDispatchData: {
                isRefresh,
            },
            toastErrorMessage: 'Unable to retrieve plan rates',
        }
    );
