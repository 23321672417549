import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const LIST_AGE_PREMIUM_RATIOS_ACTION = 'LIST_AGE_PREMIUM_RATIOS';

export const listAgePremiumRatios = () =>
    commonAction(
        async () => new PathwayBlueprintApi().listAgePremiumRatios(),
        LIST_AGE_PREMIUM_RATIOS_ACTION
    );
