import commonAction from 'actions/commonAction';
import { IHouseholdMemberDto } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_HOUSEHOLD_MEMBER_ACTION = 'PATCH_HOUSEHOLD_MEMBER';

export const patchHouseholdMember = (
    householdMemberId: string,
    year: number | undefined,
    householdMember: Partial<IHouseholdMemberDto>
) => {
    const householdMemberPatch = objectToPatchOperationsArray(householdMember);
    return commonAction(
        async () =>
            new UsersApi().patchHouseholdMember(householdMemberId, year, householdMemberPatch),
        PATCH_HOUSEHOLD_MEMBER_ACTION,
        {
            toastErrorMessage: 'Unable to update the household member',
        }
    );
};
