import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION =
    'GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT';

export const getStateBasedExchangeStatesShouldForceAdvisement = (userId: string) =>
    commonAction(
        async () => new MarketplaceApi().getStateBasedExchangeStatesShouldForceAdvisement(userId),
        GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION
    );
