import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const RESUBMIT_SUBMITTED_EXPENSE_ACTION = 'RESUBMIT_SUBMITTED_EXPENSE';

export const resubmitSubmittedExpense = (
    globalId: string,
    userId: string,
    filesAndNames: FileAndName[]
) =>
    addRhFiles(
        RESUBMIT_SUBMITTED_EXPENSE_ACTION,
        async (fileParameters, names, cancelToken) =>
            new SubmittedExpensesApi().resubmitSubmittedExpense(
                globalId,
                userId,
                fileParameters,
                names,
                cancelToken
            ),
        filesAndNames,
        'Successfully uploaded new files',
        undefined,
        undefined,
        'Unable to upload new files'
    );
