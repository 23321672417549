import commonAction from 'actions/commonAction';
import { Token } from 'api/generated/models';
import TokenApi from 'api/generated/TokenApi';
import { TokenService } from 'security/TokenService';

export const INVALIDATE_TOKEN_ACTION = 'INVALIDATE_TOKEN';

export const invalidateToken = (
    accessToken: string | undefined,
    refreshToken: string | undefined,
    shouldRestoreExistingToken = false
) =>
    commonAction(
        async () => new TokenApi().invalidate(new Token({ accessToken, refreshToken })),
        INVALIDATE_TOKEN_ACTION,
        {
            customErrorMessage: 'Unable to invalidate token',
            successOverride: ({ dispatch }) => {
                dispatch({
                    type: INVALIDATE_TOKEN_ACTION.success,
                });
                if (shouldRestoreExistingToken) {
                    TokenService.restoreExistingToken();
                } else {
                    TokenService.clearTokenFromCache();
                }
            },
        }
    );
