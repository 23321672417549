import commonAction from 'actions/commonAction';
import { IProvider, Provider } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const SET_PROVIDERS_FOR_USER_ACTION = 'SET_PROVIDERS_FOR_USER';

export const setProvidersForUser = (userId: string | undefined, providers: IProvider[]) =>
    commonAction(
        async () =>
            new UsersApi().setProvidersForUser(
                userId,
                providers.map((x) => new Provider(x))
            ),
        SET_PROVIDERS_FOR_USER_ACTION,
        {
            additionalDispatchData: { entityId: userId },
        }
    );
