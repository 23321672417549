import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const BULK_SET_ALLOW_RECURRING_EXPENSES_ACTION = 'BULK_SET_ALLOW_RECURRING_EXPENSES';

export const bulkSetAllowRecurringExpenses = (
    teamId: string,
    requestYear: number,
    allowRecurringExpenses: boolean
) =>
    commonAction(
        async () =>
            new TeamsApi().bulkSetAllowRecurringExpenses(
                teamId,
                requestYear,
                allowRecurringExpenses
            ),
        BULK_SET_ALLOW_RECURRING_EXPENSES_ACTION,
        {
            toastErrorMessage:
                'Unable to update Allow Recurring Expenses value for all team members.',
            toastSuccessMessage:
                'Successfully updated Allow Recurring Expenses value for all team members.',
        }
    );
