import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';

export const DELETE_USER_ACTION = 'DELETE_USER';

export const deleteUser = (userId: string | undefined) =>
    commonAction(async () => new UsersApi().deleteUser(userId), DELETE_USER_ACTION, {
        customErrorMessage: 'Failed to delete user',
        successHook: ({ dispatch, getState, response }) => {
            dispatch(
                listPagedUserProfiles(
                    response.data.teamId,
                    +getState().profileState.selectedYear,
                    getState().peopleState
                )
            );
        },
        toastErrorMessage: 'Failed to delete user',
        toastSuccessMessage: 'User deleted',
    });
