import commonAction from 'actions/commonAction';
import HrsApi from 'api/generated/HrsApi';

export const GET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES = 'GET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES';

export const getHrsBenefitsEligibilityClassCodes = (teamId: string | undefined, hrsIntegrationProvider: number) =>
    commonAction(
        async () => new HrsApi().listHrsBenefitsEligibilityClassCodesForTeam(teamId, hrsIntegrationProvider),
        GET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES
    );
