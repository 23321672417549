import commonAction from 'actions/commonAction';
import TasksApi from 'api/generated/TasksApi';

export const GET_COMPLETED_USER_TASKS_ACTION = 'GET_COMPLETED_USER_TASKS';

export const getCompletedUserTasks = (userId?: string | undefined) =>
    commonAction(
        async () => new TasksApi().listCompletedUserTasks(userId),
        GET_COMPLETED_USER_TASKS_ACTION
    );
