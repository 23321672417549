import commonAction from 'actions/commonAction';
import { Note } from 'api/generated/models';
import NotesApi from 'api/generated/NotesApi';

export const EDIT_TEAM_NOTE_ACTION = 'EDIT_TEAM_NOTE';

export const editTeamNote = (note: Note) =>
    commonAction(async () => new NotesApi().editTeamNote(note), EDIT_TEAM_NOTE_ACTION, {
        toastErrorMessage: 'Failed to edit note',
        toastSuccessMessage: 'Successfully edited note',
    });
