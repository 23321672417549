import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';
import { PlanDetailsAndRatesRequest } from 'api/generated/models';

export const LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION =
    'LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION';

export const listDetailsForHousehold = (userId: string, request: PlanDetailsAndRatesRequest) =>
    commonAction(
        async () => new MarketplaceApi().listDetailsForPlansForHousehold(userId, request),
        LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION,
        {
            toastErrorMessage: 'Unable to retrieve plan rates',
        }
    );
