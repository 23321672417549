import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION = 'GET_PATHWAY_BLUEPRINT_DATA_FOR_USER';

export const getPathwayBlueprintDataForUser = (
    userId: string | undefined,
    year: number | undefined
) =>
    commonAction(
        async () => new PathwayBlueprintApi().getPathwayBlueprintDataForUser(userId, year),
        GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION
    );
