import commonAction from 'actions/commonAction';
import { IPayrollReportDto } from 'api/generated/models';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';
import { MONTHS } from 'constants/date';

export const DELETE_PAYROLL_REPORT_ACTION = 'DELETE_PAYROLL_REPORT';

export const deletePayrollReport = (payrollReport: IPayrollReportDto | undefined) => {
    const monthYear = `${MONTHS[(payrollReport?.month ?? NaN) - 1]?.name} ${payrollReport?.year}`;
    return commonAction(
        async () => new PayrollReportsApi().deletePayrollReport(payrollReport?.id),
        DELETE_PAYROLL_REPORT_ACTION,
        {
            toastErrorMessage: `Unable to delete payroll report for ${monthYear}`,
            toastSuccessMessage: `Deleted payroll report for ${monthYear}`,
        }
    );
};
