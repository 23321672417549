import commonAction from 'actions/commonAction';
import { getTeamPathwayBlueprints } from 'actions/pathwayBlueprint/getTeamPathwayBlueprints';
import { IPathwayBlueprint, PathwayBlueprint } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const EDIT_PATHWAY_BLUEPRINT_ACTION = 'EDIT_PATHWAY_BLUEPRINT_ACTION';

export const editPathwayBlueprint = (pathwayBlueprint: Partial<IPathwayBlueprint>) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().editPathwayBlueprint(
                new PathwayBlueprint(pathwayBlueprint as IPathwayBlueprint)
            ),
        EDIT_PATHWAY_BLUEPRINT_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getTeamPathwayBlueprints(pathwayBlueprint?.teamId));
            },
            toastErrorMessage: (response) =>
                `Unable to update Pathway Blueprint. ${response.error.response?.data}`,
            toastSuccessMessage: 'Pathway Blueprint has been updated',
        }
    );
