import commonAction from 'actions/commonAction';
import { UserStatus } from 'api/generated/enums';
import UsersApi from 'api/generated/UsersApi';
import { push } from 'connected-react-router';

export const SET_USER_STATUS_ACTION = 'SET_USER_STATUS';

export const setUserStatus = (
    userId: string | undefined,
    status: UserStatus,
    isCurrent: boolean,
    {
        onSuccessAction,
        redirect,
        toastSuccessMessage = '',
    }: { onSuccessAction?: unknown; redirect?: string; toastSuccessMessage?: string } = {},
    sendConfirmationEmail = true,
    terminationDate?: string
) =>
    commonAction(
        async () =>
            new UsersApi().setUserStatus(userId, sendConfirmationEmail, terminationDate, status),
        SET_USER_STATUS_ACTION,
        {
            toastSuccessMessage,
            additionalDispatchData: { isCurrent },
            successHook: ({ dispatch }) => {
                redirect && dispatch(push(redirect));
                onSuccessAction && dispatch(onSuccessAction);
            },
        }
    );
