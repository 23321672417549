import commonAction from 'actions/commonAction';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';

export const UPDATE_MEMBER_COSTS_FOR_TEAM_BENEFIT_TERM_DETAIL =
    'UPDATE_MEMBER_COSTS_FOR_TEAM_BENEFIT_TERM_DETAIL';

export const updateMemberCostsForTeamBenefitTermDetail = (
    teamBenefitTermDetailId: string | undefined
) => {
    const toastErrorMessage = 'Failed to update member costs for team benefit term detail';
    return commonAction(
        async () =>
            new TeamBenefitsApi().updateMemberCostsForTeamBenefitTermDetail(
                teamBenefitTermDetailId
            ),
        UPDATE_MEMBER_COSTS_FOR_TEAM_BENEFIT_TERM_DETAIL,
        {
            toastErrorMessage,
            toastSuccessMessage: 'Member costs for team benefit term detail updated',
        }
    );
};
