import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_SUBMITTED_EXPENSE_ACTION = 'GET_SUBMITTED_EXPENSE';

export const getSubmittedExpense = (globalId: string | undefined) =>
    commonAction(
        async () => new SubmittedExpensesApi().getSubmittedExpense(globalId),
        GET_SUBMITTED_EXPENSE_ACTION
    );
