import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';

export const SET_PAYROLL_REPORT_NOTE_ACTION = 'SET_PAYROLL_REPORT_NOTE';

export const setPayrollReportNote = (payrollReportId: string | undefined, note: string) =>
    commonAction(
        async () => new PayrollReportsApi().setPayrollReportNote(payrollReportId, note),
        SET_PAYROLL_REPORT_NOTE_ACTION,
        {
            toastErrorMessage: 'Failed to set payroll report note',
            toastSuccessMessage: 'Successfully set payroll report note',
        }
    );
