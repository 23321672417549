import commonAction from 'actions/commonAction';
import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { IUserRole, UserRole } from 'api/generated/models';
import RolesApi from 'api/generated/RolesApi';

export const ADD_USER_ROLE_ACTION = 'ADD_USER_ROLE';

export const addUserRole = (
    teamId: string,
    userId: string | undefined,
    roleId: string,
    toastSuccessMessage?: string,
    roleIdsToLoad?: string[]
) =>
    commonAction(
        async () => new RolesApi().addUser(new UserRole({ roleId, userId } as IUserRole)),
        ADD_USER_ROLE_ACTION,
        {
            toastSuccessMessage,
            successHook: ({ dispatch }) => {
                dispatch(getTeamUserRoles(teamId, ...(roleIdsToLoad ?? [roleId])));
            },
        }
    );
