import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
export const ADD_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION = 'ADD_TEAM_BENEFIT_CARRIERS_BY_TYPE';

export const addTeamBenefitCarriersByType = (
    teamBenefitTypeId: number,
    newTeamBenefitCarriers: string[]
) =>
    commonAction(
        async () =>
            new TeamsApi().addTeamBenefitCarriersByType(teamBenefitTypeId, newTeamBenefitCarriers),
        ADD_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION
    );
