import commonAction from 'actions/commonAction';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';

export const UNLINK_ENROLLMENT_VERIFICATION_RH_FILE_ACTION =
    'UNLINK_ENROLLMENT_VERIFICATION_RH_FILE';

export const unlinkEnrollmentVerificationRhFile = (
    enrollmentVerificationId: string,
    rhFileId: string
) =>
    commonAction(
        async () =>
            new EnrollmentVerificationsApi().unlinkRhFile(enrollmentVerificationId, rhFileId),
        UNLINK_ENROLLMENT_VERIFICATION_RH_FILE_ACTION,
        {
            toastErrorMessage: 'Unable to unlink verification document file',
        }
    );
