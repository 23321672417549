import commonAction from 'actions/commonAction';
import { IResource, Resource } from 'api/generated/models';
import ResourcesApi from 'api/generated/ResourcesApi';

export const ADD_RESOURCE_ACTION = 'ADD_RESOURCE';

export const addResource = (resource: IResource) =>
    commonAction(
        async () => new ResourcesApi().addResource(new Resource(resource)),
        ADD_RESOURCE_ACTION,
        {
            toastErrorMessage: 'Unable to add resource',
            toastSuccessMessage: 'Successfully added resource',
        }
    );
