import commonAction from 'actions/commonAction';
import { getUserProfile } from 'actions/user/getUserProfile';
import { IMemberQuoteInfo } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_MEMBER_QUOTE_INFO_ACTION = 'PATCH_MEMBER_QUOTE_INFO';

export const patchMemberQuoteInfo = (
    userId: string | undefined,
    year: number | undefined,
    memberQuoteInfo: Partial<IMemberQuoteInfo>,
    isCurrent: boolean
) => {
    const memberQuoteInfoPatch = objectToPatchOperationsArray(memberQuoteInfo);
    return commonAction(
        async () => new UsersApi().patchMemberQuoteInfo(userId, memberQuoteInfoPatch),
        PATCH_MEMBER_QUOTE_INFO_ACTION,
        {
            additionalDispatchData: { isCurrent, params: { userId } },
            nonSuccessHook: ({ dispatch }) => dispatch(getUserProfile(userId, isCurrent, year)),
            toastErrorMessage: 'Unable to update the user',
        }
    );
};
