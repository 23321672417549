import commonAction from 'actions/commonAction';
import { IYearlyUserInfoDto } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { objectToPatchOperationsArray } from 'api/utilities';
import { push } from 'connected-react-router';
import { RecursivePartial } from 'utilities';

export const PATCH_YEARLY_USER_INFO_ACTION = 'PATCH_YEARLY_USER_INFO';

export const patchYearlyUserInfo = (
    userId: string | undefined,
    year: number | undefined,
    yearlyUserInfo: RecursivePartial<IYearlyUserInfoDto>,
    isCurrent: boolean,
    {
        redirect,
        toastErrorMessage = 'Unable to edit yearly user info',
        toastSuccessMessage = '',
    }: {
        redirect?: string;
        toastErrorMessage?: string;
        toastSuccessMessage?: JSX.Element | string;
    } = {}
) => {
    const yearlyUserInfoPatch = objectToPatchOperationsArray(yearlyUserInfo, true);
    return commonAction(
        async () => new UsersApi().patchYearlyInfo(userId, year, yearlyUserInfoPatch),
        PATCH_YEARLY_USER_INFO_ACTION,
        {
            toastErrorMessage,
            toastSuccessMessage,
            additionalDispatchData: { isCurrent },
            successHook: ({ dispatch }) => {
                redirect && dispatch(push(redirect));
            },
        }
    );
};
