import commonAction from 'actions/commonAction';
import TaskFlowsApi from 'api/generated/TaskFlowsApi';
import { CompleteTaskRequest } from 'api/generated/models';
import { push } from 'connected-react-router';
import { IchraFlowResponseType } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import { hasValue } from 'utilities/index';

export const COMPLETE_TASK_ACTION = 'COMPLETE_TASK';

export const completeTask = (
    userFlowTaskStatusGlobalId: string,
    response: IchraFlowResponseType,
    redirect?: string,
    toastErrorMessage?: string,
    toastSuccessMessage?: string
) =>
    commonAction(
        async () =>
            new TaskFlowsApi().completeTask(
                new CompleteTaskRequest({
                    response,
                    userFlowTaskStatusGlobalId,
                })
            ),
        COMPLETE_TASK_ACTION,
        {
            toastSuccessMessage,
            successHook: ({ dispatch }) => {
                hasValue(redirect) && dispatch(push(redirect));
            },
            toastErrorMessage: (errorResponse) =>
                `${errorResponse.error.response?.data ? JSON.stringify(errorResponse.error.response.data) : toastErrorMessage}`


        }
    );
