import commonAction from 'actions/commonAction';
import MedicaidApi from 'api/generated/MedicaidApi';
import { IMedicaidDetails } from 'api/generated/models';
import { objectToPatchOperationsArray } from 'api/utilities';

export const CREATE_OR_PATCH_MEDICAID_DETAILS_ACTION = 'CREATE_OR_PATCH_MEDICAID_DETAILS';

export const createOrPatchMedicaidDetails = (
    userId: string,
    details: Partial<IMedicaidDetails>
) => {
    const detailsPatch = objectToPatchOperationsArray(details);
    return commonAction(
        async () => new MedicaidApi().createOrPatch(userId, detailsPatch),
        CREATE_OR_PATCH_MEDICAID_DETAILS_ACTION,
        {
            toastErrorMessage: 'Unable to update Medicaid details.',
            toastSuccessMessage: 'Medicaid details updated successfully',
        }
    );
};
