import commonAction from 'actions/commonAction';
import { ResetPassword } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { push } from 'connected-react-router';
import { RESET_PASSWORD_PATH } from 'routers/routes';

export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD';

export const resetPassword = (password: string | undefined, token: string | undefined) =>
    commonAction(
        async () => new UsersApi().resetPassword(new ResetPassword({ password, token })),
        RESET_PASSWORD_ACTION,
        {
            customErrorMessage: (response) =>
                `Sorry, an error occurred (${response.error.response?.status}).  We are unable to reset your password at this time.`,
            successHook: ({ dispatch }) => dispatch(push(`${RESET_PASSWORD_PATH}?complete`)),
        }
    );
