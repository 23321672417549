import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_ENROLLMENT_DATA_ACTION = 'GET_ENROLLMENT_DATA';

export const getEnrollmentData = (userId: string, year: number) =>
    commonAction(
        async () => new UsersApi().getDataForEnrollment(userId, year),
        GET_ENROLLMENT_DATA_ACTION,
        { toastErrorMessage: 'Unable to get data for enrollment' }
    );
