import commonAction from 'actions/commonAction';
import CustomQuestionsApi from 'api/generated/CustomQuestionsApi';

export const GET_ACTIVE_QUESTIONS_ACTION = 'GET_ACTIVE_QUESTIONS';

export const getActiveQuestions = (teamId: string | undefined) =>
    commonAction(
        async () => new CustomQuestionsApi().getActiveQuestions(teamId),
        GET_ACTIVE_QUESTIONS_ACTION,
        {
            toastErrorMessage: 'Unable to retrieve questions',
        }
    );
