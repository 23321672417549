import commonAction from 'actions/commonAction';
import { IPathwayBlueprint, PathwayBlueprint } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { TEAMS_PATHWAY_BLUEPRINT_PATH } from 'routers/routes';

export const ADD_PATHWAY_BLUEPRINT_ACTION = 'ADD_PATHWAY_BLUEPRINT_ACTION';

export const addPathwayBlueprint = (pathwayBlueprint: Partial<IPathwayBlueprint>) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().addPathwayBlueprint(
                new PathwayBlueprint(pathwayBlueprint as IPathwayBlueprint)
            ),
        ADD_PATHWAY_BLUEPRINT_ACTION,
        {
            successHook: ({
                dispatch,
                response: {
                    data: { id, teamId },
                },
            }) =>
                dispatch(
                    push(
                        generatePath(
                            generatePath(TEAMS_PATHWAY_BLUEPRINT_PATH, {
                                teamId,
                                pathwayBlueprintId: id,
                            })
                        )
                    )
                ),
            toastErrorMessage: 'Unable to create Pathway Blueprint',
            toastSuccessMessage: 'Pathway Blueprint has been created',
        }
    );
