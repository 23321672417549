import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const REMOVE_USER_RELATIONSHIP_ACTION = 'REMOVE_USER_RELATIONSHIP';

export const deleteUserRelationship = (
    relationshipId: string | undefined,
    isCurrent: boolean,
    {
        toastErrorMessage = '',
        toastSuccessMessage = '',
    }: { toastErrorMessage?: string; toastSuccessMessage?: string } = {}
) =>
    commonAction(
        async () => new UsersApi().removeUserRelationship(relationshipId),
        REMOVE_USER_RELATIONSHIP_ACTION,
        {
            toastErrorMessage,
            toastSuccessMessage,
            additionalDispatchData: { isCurrent },
        }
    );
