import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
import HTTP_STATUS from 'constants/responseStatuses';

export const GET_LAUNCH_DETAILS_ACTION = 'GET_LAUNCH_DETAILS';

export const getLaunchDetails = (teamId: string) =>
    commonAction(async () => new TeamsApi().getLaunchDetails(teamId), GET_LAUNCH_DETAILS_ACTION, {
        nonSuccessOverride: ({ dispatch, response }) => {
            if (response.status === HTTP_STATUS.NO_CONTENT) {
                dispatch({
                    data: {},
                    type: GET_LAUNCH_DETAILS_ACTION.success,
                });
            } else {
                dispatch({
                    errorMessage: response.error.response?.data,
                    statusCode: response.error.response?.status,
                    statusText: response.error.response?.status,
                    type: GET_LAUNCH_DETAILS_ACTION.failed,
                });
            }
        },
    });
