import commonAction from 'actions/commonAction';
import AdpApi from 'api/generated/AdpApi';

export const SYNC_USERS_WITH_ADP = 'SYNC_USERS_WITH_ADP';

export const syncUsersWithAdp = (teamId: string) =>
    commonAction(async () => new AdpApi().syncUsers(teamId), SYNC_USERS_WITH_ADP, {
        toastErrorMessage: 'Failed to sync with ADP',
        toastSuccessMessage: 'Starting sync with ADP',
    });
