import commonAction from 'actions/commonAction';
import NotesApi from 'api/generated/NotesApi';

export const DELETE_USER_NOTE_ACTION = 'DELETE_USER_NOTE';

export const deleteUserNote = (noteId: string) =>
    commonAction(async () => new NotesApi().deleteUserNote(noteId), DELETE_USER_NOTE_ACTION, {
        toastErrorMessage: 'Failed to delete note',
        toastSuccessMessage: 'Successfully deleted note',
    });
