import commonAction from 'actions/commonAction';
import ResourcesApi from 'api/generated/ResourcesApi';

export const DELETE_RESOURCE_ACTION = 'DELETE_RESOURCE';

export const deleteResource = (id: string | undefined) =>
    commonAction(async () => new ResourcesApi().deleteResource(id), DELETE_RESOURCE_ACTION, {
        toastErrorMessage: 'Unable to delete resource',
        toastSuccessMessage: 'Successfully deleted resource',
    });
