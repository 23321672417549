import { IHouseholdMemberDto } from 'api/generated/models';

export const APPLY_SHOPPING_COVERAGE_CHANGES = 'APPLY_SHOPPING_COVERAGE_CHANGES';

export const applyShoppingCoverageChanges = (
    householdMembers: Record<string, IHouseholdMemberDto>
) => ({
    data: { householdMembers },
    type: APPLY_SHOPPING_COVERAGE_CHANGES,
});
