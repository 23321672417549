import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_EXPENSE_TYPES_ACTION = 'GET_EXPENSE_TYPES';

export const getExpenseTypes = () =>
    commonAction(
        async () => new SubmittedExpensesApi().getExpenseTypes(),
        GET_EXPENSE_TYPES_ACTION
    );
