import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const SEARCH_DRUGS_ACTION = 'SEARCH_DRUGS';

export const searchDrugs = (query: string) =>
    commonAction(
        async () => new MarketplaceApi().searchPrescriptionDrugs(query),
        SEARCH_DRUGS_ACTION
    );
