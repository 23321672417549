import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const SUBMIT_EXPENSE_ACTION = 'SUBMIT_EXPENSE';

export const submitExpense = (
    userId: string,
    coveredMemberId: string,
    expenseTypeId: number,
    expenseAmount: number,
    expenseSource: string,
    dateOfService: string,
    files: FileAndName[],
    isRecurringExpense: boolean | undefined,
    reimburseToHsa: boolean | undefined
) => {
    console.log(
        'userId',
        userId,
        '\ncoveredMemberId',
        coveredMemberId,
        '\nexpenseTypeId',
        expenseTypeId,
        '\nexpenseAmount',
        expenseAmount,
        '\ndateOfService',
        dateOfService,
        '\nexpenseSource',
        expenseSource,
        '\nreimburseToHsa',
        reimburseToHsa,
        '\nisRecurringExpense',
        isRecurringExpense
    );
    console.log('files\n', files);

    return addRhFiles(
        SUBMIT_EXPENSE_ACTION,
        async (fileParameters, names, cancelToken) =>
            new SubmittedExpensesApi().submitExpense(
                userId,
                coveredMemberId,
                expenseTypeId,
                expenseAmount,
                dateOfService,
                expenseSource,
                reimburseToHsa,
                isRecurringExpense,
                fileParameters,
                names,
                cancelToken
            ),
        files,
        'Expense successfully submitted',
        undefined,
        undefined,
        'Failed to submit expense'
    );
};
