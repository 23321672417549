import commonAction from 'actions/commonAction';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';

export const DELETE_ENROLLMENT_VERIFICATION_ACTION = 'DELETE_ENROLLMENT_VERIFICATION';

export const deleteEnrollmentVerification = (enrollmentVerificationId: string) =>
    commonAction(
        async () => new EnrollmentVerificationsApi().delete(enrollmentVerificationId),
        DELETE_ENROLLMENT_VERIFICATION_ACTION,
        {
            toastErrorMessage: 'Unable to delete verification document',
        }
    );
