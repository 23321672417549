import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const TOGGLE_REIMBURSEMENT_REPORT_SHARED_ACTION = 'TOGGLE_REIMBURSEMENT_REPORT_SHARED';

export const toggleReimbursementReportShared = (
    reportGlobalId: string | undefined,
    sharingPrefix: '' | 'un-'
) =>
    commonAction(
        async () => new SubmittedExpensesApi().toggleReimbursementReportShared(reportGlobalId),
        TOGGLE_REIMBURSEMENT_REPORT_SHARED_ACTION,
        {
            toastErrorMessage: `Error ${sharingPrefix}sharing Reimbursement Report`,
            toastSuccessMessage: `Reimbursement Report successfully ${sharingPrefix}shared`,
        }
    );
