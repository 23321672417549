import commonAction from 'actions/commonAction';
import { getUserProfile } from 'actions/user/getUserProfile';
import { AddressTypes } from 'api/generated/enums';
import { Address, IAddress } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const EDIT_USER_HOME_ADDRESS_ACTION = 'EDIT_USER_HOME_ADDRESS';
export const EDIT_USER_MAILING_ADDRESS_ACTION = 'EDIT_USER_MAILING_ADDRESS_ACTION';

export const addOrEditUserAddress = (
    userId: string | undefined,
    year: number,
    addressType: AddressTypes,
    isCurrent: boolean,
    address: IAddress
) =>
    commonAction(
        async () =>
            new UsersApi().addOrEditUserAddress(userId, year, addressType, new Address(address)),
        addressType === AddressTypes.Home
            ? EDIT_USER_HOME_ADDRESS_ACTION
            : EDIT_USER_MAILING_ADDRESS_ACTION,
        {
            additionalDispatchData: { isCurrent },
            toastErrorMessage: 'Unable to update the address',
            unconditionalHook: ({ dispatch }) => dispatch(getUserProfile(userId, isCurrent, year)),
        }
    );
