import commonAction from 'actions/commonAction';
import PaymentsApi from 'api/generated/PaymentsApi';

export const GET_ACCOUNT_SNAPSHOT_ACTION = 'GET_ACCOUNT_SNAPSHOT';

export const getAccountSnapshot = (userId: string) =>
    commonAction(
        async () => new PaymentsApi().getAccountSnapshot(userId),
        GET_ACCOUNT_SNAPSHOT_ACTION,
        {
            toastErrorMessage: 'Unable to get payments info',
        }
    );
