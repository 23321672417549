import commonAction from 'actions/commonAction';
import { ITeam } from 'api/generated/models';
import SurveyApi from 'api/generated/SurveyApi';
import Toast from 'components/Toast';

export const SEND_SURVEY_EMAILS_TO_TEAM_ACTION = 'SEND_SURVEY_EMAILS_TO_TEAM';

export const sendSurveyEmailsToTeam = (team: ITeam | undefined, sendToAllMembers: boolean) => {
    const toastId = Toast.success(`Sending surveys to ${team?.name} members...`);
    const errorMessage = `Failed to send surveys to ${team?.name} members`;
    return commonAction(
        async () => new SurveyApi().sendMemberSurveyEmailsToTeam(team?.teamId, sendToAllMembers),
        SEND_SURVEY_EMAILS_TO_TEAM_ACTION,
        {
            customErrorMessage: errorMessage,
            successHook: () =>
                Toast.update(toastId, { render: `Surveys sent to ${team?.name} members` }),
            toastErrorMessage: errorMessage,
        }
    );
};
