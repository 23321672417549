import { clearTeamFinch } from 'actions/clear';
import commonAction from 'actions/commonAction';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import FinchApi from 'api/generated/FinchApi';

export const DISCONNECT_FINCH_ACTION = 'CREATE_FINCH_ACCESS_TOKEN_ACTION';

export const disconnectFinch = (teamId: string) =>
    commonAction(async () => new FinchApi().disconnect(teamId), DISCONNECT_FINCH_ACTION, {
        successHook: ({ dispatch }) => {
            dispatch(getTeamProfile(teamId));
            dispatch(clearTeamFinch());
        },
        toastErrorMessage: 'Failed to disconnect from Finch',
        toastSuccessMessage: 'Successfully disconnected Finch',
    });
