import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT = 'APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT';

export const applyCalculationForPathwayBlueprint = (
    blueprintId: string | undefined,
    pathwayUserIds: string[]
) => {
    const isSingleUser = pathwayUserIds.length === 1;
    const additionalDispatchData: {
        isSingleUser: boolean;
        params: { isBulk?: true; pathwayUserId?: string };
    } = {
        isSingleUser,
        params: { isBulk: true },
    };
    if (isSingleUser) {
        additionalDispatchData.params = {
            pathwayUserId: pathwayUserIds[0],
        };
    }
    return commonAction(
        async () =>
            new PathwayBlueprintApi().applyCalculationForPathwayBlueprint(
                blueprintId,
                pathwayUserIds
            ),
        APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT,
        {
            additionalDispatchData,
            toastErrorMessage: 'Failed to apply Contributions',
            toastSuccessMessage: 'Applied Contributions successfully',
        }
    );
};
