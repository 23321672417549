import commonAction from 'actions/commonAction';
import TaskFlowsApi from 'api/generated/TaskFlowsApi';

export const OPEN_UP_SHOPPING_ACTION = 'OPEN_UP_SHOPPING';

export const openUpShopping = (userId: string) =>
    commonAction(async () => new TaskFlowsApi().openUpShopping(userId), OPEN_UP_SHOPPING_ACTION, {
        additionalDispatchData: { userId },
        toastErrorMessage: 'Failed to open up shopping',
        toastSuccessMessage: 'Successfully opened up shopping',
    });
