import commonAction from 'actions/commonAction';
import CustomQuestionsApi from 'api/generated/CustomQuestionsApi';

export const TOGGLE_ARCHIVE_CUSTOM_QUESTION_ACTION = 'TOGGLE_ARCHIVE_CUSTOM_QUESTION';

export const toggleArchiveCustomQuestion = (questionId: string, isArchived: boolean) =>
    commonAction(
        async () => new CustomQuestionsApi().toggleCustomQuestionArchived(questionId),
        TOGGLE_ARCHIVE_CUSTOM_QUESTION_ACTION,
        {
            toastErrorMessage: `Failed to ${isArchived ? 'un-archive' : 'archive'} question`,
            toastSuccessMessage: `Successfully ${isArchived ? 'un-archived' : 'archived'} question`,
        }
    );
