import commonAction from 'actions/commonAction';
import {
    EditUserTeamBenefitTermDetailsRequest,
    IEditUserTeamBenefitTermDetailsRequest,
} from 'api/generated/models';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';
import Toast from 'components/Toast';
import { hasValue } from 'utilities';

export const EDIT_USER_TEAM_BENEFIT_TERM_DETAILS_ACTION = 'EDIT_USER_TEAM_BENEFIT_TERM_DETAILS';

export const editUserTeamBenefitTermDetails = (
    teamBenefitTermId: string,
    editUserTeamBenefitTermDetailsRequest: IEditUserTeamBenefitTermDetailsRequest
) =>
    commonAction(
        async () =>
            new TeamBenefitsApi().editUserTeamBenefitTermDetails(
                teamBenefitTermId,
                new EditUserTeamBenefitTermDetailsRequest(editUserTeamBenefitTermDetailsRequest)
            ),
        EDIT_USER_TEAM_BENEFIT_TERM_DETAILS_ACTION,
        {
            successHook({ response }) {
                const message = response?.data;
                if (hasValue(message)) {
                    Toast.error(message);
                }
            },
            toastErrorMessage: 'Error editing User Team Benefits',
            toastSuccessMessage: 'Successfully edited User Team Benefits',
        }
    );
