import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_TEAM_REPRESENTATIVE_OPTIONS_ACTION = 'GET_TEAM_REPRESENTATIVE_OPTIONS';

export const getTeamRepresentativeOptions = (teamId: string) =>
    commonAction(
        async () => new UsersApi().listRepresentativeOptionsForTeam(teamId),
        GET_TEAM_REPRESENTATIVE_OPTIONS_ACTION
    );
