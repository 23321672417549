import commonAction from 'actions/commonAction';
import { ITeamPlan } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_TEAM_PLAN_ACTION = 'PATCH_TEAM_PLAN';

export const patchTeamPlan = (teamPlanId: string, teamPlan: Partial<ITeamPlan>) => {
    const teamPlanPatch = objectToPatchOperationsArray(teamPlan);
    return commonAction(
        async () => new TeamsApi().patchTeamPlan(teamPlanId, teamPlanPatch),
        PATCH_TEAM_PLAN_ACTION,
        {
            toastErrorMessage: 'Unable to update the team plan',
        }
    );
};
