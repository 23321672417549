import commonAction from 'actions/commonAction';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';
import { IManageMembersState } from 'reducers/manageMembersState';

export const PAGE_USER_TERM_DETAILS_ACTION = 'PAGE_USER_TERM_DETAILS';

export const pageUserTermDetails = (
    teamBenefitTermDetailId: string,
    teamId: string,
    paginationParams: IManageMembersState
) =>
    commonAction(
        async () =>
            new TeamBenefitsApi().pageUserTermDetails(
                teamId,
                teamBenefitTermDetailId,
                paginationParams.showOnlyInactive,
                paginationParams.coverageLevelFilters,
                paginationParams.userTermDetailStatusFilters,
                paginationParams.page,
                paginationParams.pageSize,
                paginationParams.search,
                paginationParams.orderings
            ),
        PAGE_USER_TERM_DETAILS_ACTION
    );
