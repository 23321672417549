import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';
import HTTP_STATUS from 'constants/responseStatuses';

export const CALCULATE_PATHWAY_BLUEPRINT_ACTION = 'CALCULATE_PATHWAY_BLUEPRINT';

export const calculatePathwayBlueprint = (blueprintId: string | undefined) =>
    commonAction(
        async () => new PathwayBlueprintApi().calculatePathwayBlueprint(blueprintId),
        CALCULATE_PATHWAY_BLUEPRINT_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(blueprintId));
            },
            toastErrorMessage: (response) => {
                if (response.error.response?.status === HTTP_STATUS.CONFLICT) {
                    return 'Pathway Blueprint is already calculating Wage+ amounts.';
                } else {
                    return `Unable to calculate Wage+ amounts ${response.error.response?.data}`;
                }
            },
            toastSuccessMessage: 'Successfully started Wage+ calculation.',
        }
    );
