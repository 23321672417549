import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
import { CancelToken } from 'axios';
import { ITeamsState } from 'pages/teams/teamsState';

export const LIST_PAGED_TEAM_PROFILES_ACTION = 'LIST_PAGED_TEAM_PROFILED_ACTION';

export const listPagedTeamProfiles = (paginationParams: ITeamsState, token?: CancelToken) => {
    const {
        includeArchived,
        orderings,
        page,
        pageSize,
        search,
        teamStateFilters,
    } = paginationParams;
    return commonAction(
        async () =>
            new TeamsApi().pageTeamProfiles(
                includeArchived,
                teamStateFilters,
                page,
                pageSize,
                search,
                orderings,
                token
            ),
        LIST_PAGED_TEAM_PROFILES_ACTION
    );
};
