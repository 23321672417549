import commonAction from 'actions/commonAction';
import { IPathwayBlueprint } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';
import { CancelToken } from 'axios';

type SuccessCallback = (pathwayBlueprint: IPathwayBlueprint) => void;

export const GET_PATHWAY_BLUEPRINT_ACTION = 'GET_PATHWAY_BLUEPRINT';

export const getPathwayBlueprint = (
    blueprintId: string | undefined,
    options?: { cancelToken?: CancelToken; onSuccess?: SuccessCallback }
) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().getPathwayBlueprint(blueprintId, options?.cancelToken),
        GET_PATHWAY_BLUEPRINT_ACTION,
        {
            successHook: ({ response }) => {
                options?.onSuccess?.(response.data);
            },
        }
    );
