import commonAction from 'actions/commonAction';
import { CopyTeamInfoRequest } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { getLongRunningProcessToastMessage } from 'utilities/toast';

export const COPY_HOUSEHOLD_INFO_ACTION = 'COPY_HOUSEHOLD_INFO';

export const copyHouseholdInfo = (
    teamId: string | undefined,
    sourceYear: number,
    targetYear: number | undefined,
    userId?: string
) => {
    const copyInfoRequest = new CopyTeamInfoRequest({
        sourceYear,
        targetYear: targetYear as number,
        teamId: teamId as string,
    });
    return commonAction(
        async () => new UsersApi().copyHouseholdInfo(userId, copyInfoRequest),
        COPY_HOUSEHOLD_INFO_ACTION,
        {
            toastErrorMessage(response) {
                if (response.error.message === 'Network Error') {
                    return getLongRunningProcessToastMessage('copy household data');
                } else {
                    return 'Unable to copy household data to year';
                }
            },
            toastSuccessMessage: `Household data copied from ${sourceYear} to ${targetYear} successfully`,
        }
    );
};
