import commonAction from 'actions/commonAction';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';
import { SelectAncillaryBenefitRequest } from 'api/generated/models';

export const SELECT_ANCILLARY_BENEFITS_ACTION = 'SELECT_ANCILLARY_BENEFITS';

export const selectAncillaryBenefits = (
    userId: string,
    ancillaryBenefits: SelectAncillaryBenefitRequest[],
    isCurrent: boolean
) =>
    commonAction(
        async () => new TeamBenefitsApi().selectAncillaryBenefits(userId, ancillaryBenefits),
        SELECT_ANCILLARY_BENEFITS_ACTION,
        {
            additionalDispatchData: { isCurrent },
            toastErrorMessage: `Failed to save ancillary benefit selection${
                ancillaryBenefits.length === 1 ? '' : 's'
            }`,
        }
    );
