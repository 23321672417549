import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
import { getLongRunningProcessToastMessage } from 'utilities/toast';

export const TRANSITION_TO_RENEWING_ACTION = 'TRANSITION_TO_RENEWING';

export const transitionToRenewing = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().transitionTeamToRenewing(teamId),
        TRANSITION_TO_RENEWING_ACTION,
        {
            toastErrorMessage(response) {
                if (response.error.message === 'Network Error') {
                    return getLongRunningProcessToastMessage('transition team to renewing');
                } else {
                    return 'Failed to transition team to renewing';
                }
            },
            toastSuccessMessage: 'Successfully transitioned team to renewing',
        }
    );
