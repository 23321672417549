import commonAction from 'actions/commonAction';
import { getFavoriteTeams } from 'actions/favoriteTeams/getFavoriteTeams';
import FavoriteTeamsApi from 'api/generated/FavoriteTeamsApi';

export const DELETE_FAVORITE_TEAM_ACTION = 'DELETE_FAVORITE_TEAM';

export const deleteFavoriteTeam = (favoriteTeamId: string) =>
    commonAction(
        async () => new FavoriteTeamsApi().deleteFavoriteTeam(favoriteTeamId),
        DELETE_FAVORITE_TEAM_ACTION,
        {
            successHook: ({ dispatch }) => {
                dispatch(getFavoriteTeams());
            },
        }
    );
