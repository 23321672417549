import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const LIST_REIMBURSEMENT_REPORTS_ACTION = 'LIST_REIMBURSEMENT_REPORTS';

export const listReimbursementReports = (teamId: string) =>
    commonAction(
        async () => new SubmittedExpensesApi().listReimbursementReports(teamId),
        LIST_REIMBURSEMENT_REPORTS_ACTION
    );
