import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_MOST_RECENT_REIMBURSEMENT_REPORT_ACTION =
    'GET_MOST_RECENT_REIMBURSEMENT_REPORT_ACTION';

export const getMostRecentReimbursementReport = (teamId: string) =>
    commonAction(
        async () => new SubmittedExpensesApi().getMostRecentReimbursementReport(teamId),
        GET_MOST_RECENT_REIMBURSEMENT_REPORT_ACTION
    );
