import commonAction from 'actions/commonAction';
import { ITeamBenefit, TeamBenefit } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
export const ADD_TEAM_BENEFIT_ACTION = 'ADD_TEAM_BENEFIT';

export const addTeamBenefit = (newTeamBenefit: ITeamBenefit) =>
    commonAction(
        async () => new TeamsApi().addTeamBenefit(new TeamBenefit(newTeamBenefit)),
        ADD_TEAM_BENEFIT_ACTION
    );
