import commonAction from 'actions/commonAction';
import {
    ACCOUNT_IS_LOCKED_TEXT,
    INTERMEDIATE_TOKEN_ACTION,
    INTERMEDIATE_TOKEN_EXPIRED_TEXT,
    LOCKED_OUT_ERROR_TEXT,
} from 'actions/token/tokenActionUtility';
import MfaApi from 'api/generated/MfaApi';
import { ValidateOtpCommand } from 'api/generated/models';
import has from 'lodash/has';
import { TokenService } from 'security/TokenService';

export const confirmMfaVerification = (mfaCode: string, rememberDevice: boolean) =>
    commonAction(
        async () =>
            new MfaApi()
                .withRequestOptions({ withCredentials: true })
                .validateOtp(new ValidateOtpCommand({ mfaCode, rememberDevice })),
        INTERMEDIATE_TOKEN_ACTION,
        {
            customErrorMessage: (response) => {
                const msg = (response.error.response?.data as unknown) as string;
                let errorText = msg ?? 'There was an unexpected error.  Please try again.';
                if (msg === ACCOUNT_IS_LOCKED_TEXT) {
                    errorText = LOCKED_OUT_ERROR_TEXT;
                    TokenService.clearIntermediateTokenFromCache(errorText);
                } else if (has(response.error?.response?.headers, 'token-expired')) {
                    errorText = INTERMEDIATE_TOKEN_EXPIRED_TEXT;
                    TokenService.clearIntermediateTokenFromCache(errorText);
                }
                return errorText;
            },
            successOverride: ({ response }) => {
                TokenService.decodeAndCacheToken(
                    response.data.accessToken,
                    response.data.refreshToken
                );
            },
        }
    );
