import commonAction from 'actions/commonAction';
import { TaskItems } from 'api/generated/enums';
import { CompletedUserTask, ICompletedUserTask } from 'api/generated/models';
import TasksApi from 'api/generated/TasksApi';

export const SAVE_COMPLETED_USER_TASK_ACTION = 'SAVE_COMPLETED_USER_TASK';

export const saveCompletedUserTask = (taskItemId: TaskItems) =>
    commonAction(
        async () =>
            new TasksApi().saveCompletedUserTask(
                new CompletedUserTask({ taskItemId } as ICompletedUserTask)
            ),
        SAVE_COMPLETED_USER_TASK_ACTION
    );
