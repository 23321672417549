import commonAction from 'actions/commonAction';
import { ISocialSecurityRequest, SocialSecurityRequest } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const EDIT_SOCIAL_SECURITY_FOR_HOUSEHOLD_ACTION = 'EDIT_SOCIAL_SECURITY_FOR_HOUSEHOLD';

export type IHouseholdSocialSecurityRequests = Record<string, ISocialSecurityRequest>;

export const editSocialSecurityNumbers = (
    userId: string | undefined,
    year: number | undefined,
    ssnRequests: IHouseholdSocialSecurityRequests,
    isEdit = false
) => {
    const requests = Object.keys(ssnRequests).map(
        (key) => new SocialSecurityRequest(ssnRequests[key])
    );
    return commonAction(
        async () => new UsersApi().editSocialSecurityForHousehold(userId, year, requests),
        EDIT_SOCIAL_SECURITY_FOR_HOUSEHOLD_ACTION,
        {
            toastErrorMessage: `Failed to ${isEdit ? 'edit' : 'add'} social security numbers`,
            toastSuccessMessage: `Successfully ${
                isEdit ? 'edited' : 'added'
            } social security numbers`,
        }
    );
};
