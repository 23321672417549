import commonAction from 'actions/commonAction';
import { IPayrollReportDto } from 'api/generated/models';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';
import { MONTHS } from 'constants/date';
import HTTP_STATUS from 'constants/responseStatuses';

export const REFRESH_PAYROLL_REPORT_ACTION = 'REFRESH_PAYROLL_REPORT';

export const refreshPayrollReport = (payrollReport: IPayrollReportDto | undefined) => {
    const monthYear = `${MONTHS[(payrollReport?.month ?? NaN) - 1]?.name} ${payrollReport?.year}`;
    return commonAction(
        async () => new PayrollReportsApi().refreshPayrollReport(payrollReport?.id),
        REFRESH_PAYROLL_REPORT_ACTION,
        {
            toastErrorMessage: (response) => {
                if (response.error.response?.status === HTTP_STATUS.NOT_FOUND) {
                    return `No payroll report exists for ${monthYear}`;
                } else {
                    return `Unable to refresh payroll report for ${monthYear}`;
                }
            },
            toastSuccessMessage: `Refreshed payroll report for ${monthYear}`,
        }
    );
};
