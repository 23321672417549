import commonAction from 'actions/commonAction';
import ExpenseSummaryApi from 'api/generated/ExpenseSummaryApi';
import { ExpenseSummaryCustomExpense, IExpenseSummaryCustomExpense } from 'api/generated/models';

export const SET_CUSTOM_EXPENSES_ACTION = 'SET_CUSTOM_EXPENSES';

export const setCustomExpenses = (
    expenseSummaryId: string | undefined,
    customExpenses: Partial<IExpenseSummaryCustomExpense>[]
) =>
    commonAction(
        async () =>
            new ExpenseSummaryApi().setCustomExpenses(
                expenseSummaryId,
                customExpenses.map(
                    (x) => new ExpenseSummaryCustomExpense(x as IExpenseSummaryCustomExpense)
                )
            ),
        SET_CUSTOM_EXPENSES_ACTION,
        {
            toastErrorMessage: 'Failed to save expenses',
            toastSuccessMessage: 'Successfully saved expenses',
        }
    );
