import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_YEARLY_USER_INFO_ACTION = 'GET_YEARLY_USER_INFO';

export const getYearlyUserInfo = (userId: string | undefined, year: number | undefined) =>
    commonAction(
        async () => new UsersApi().getYearlyInfo(userId, year),
        GET_YEARLY_USER_INFO_ACTION
    );
