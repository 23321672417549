import { renameRhFile } from 'actions/rhFile/renameRhFile';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const RENAME_TEAM_RH_FILES_ACTION = 'RENAME_TEAM_RH_FILES';
export const renameTeamRhFile = (teamId: string, rhFile: IRhFile) =>
    renameRhFile(
        RENAME_TEAM_RH_FILES_ACTION,
        async (newRhFile) => new RhFilesApi().renameTeamRhFile(teamId, newRhFile),
        rhFile
    );
