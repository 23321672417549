import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const GET_TEAM_OPERATIONS_INFO_ACTION = 'GET_TEAM_OPERATIONS_INFO';

export const getTeamOperationsInfo = (teamId: string, year: number | undefined) =>
    commonAction(
        async () => new TeamsApi().getTeamOperationsInfo(teamId, year),
        GET_TEAM_OPERATIONS_INFO_ACTION
    );
