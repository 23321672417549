import commonAction from 'actions/commonAction';
import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { UserRole } from 'api/generated/models';
import RolesApi from 'api/generated/RolesApi';
import { AxiosResponse } from 'axios';
import Toast from 'components/Toast';
import HTTP_STATUS from 'constants/responseStatuses';
import { Dispatch } from 'redux';

export const REMOVE_USER_ROLE_ACTION = 'REMOVE_USER_ROLE';

const successAndNoContentHandler = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    response: AxiosResponse<UserRole>,
    teamId: string,
    roleIds: string[],
    toastSuccessMessage?: string
) => {
    dispatch(getTeamUserRoles(teamId, ...roleIds));
    dispatch({
        data: response.data,
        type: REMOVE_USER_ROLE_ACTION.success,
    });
    if (toastSuccessMessage) {
        Toast.success(toastSuccessMessage);
    }
};

export const removeUserRole = (
    teamId: string,
    userId: string | undefined,
    roleId: string,
    toastSuccessMessage?: string,
    rolesIdsToLoad?: string[]
) =>
    commonAction(async () => new RolesApi().removeUser(roleId, userId), REMOVE_USER_ROLE_ACTION, {
        nonSuccessOverride: ({ dispatch, response }) => {
            if (response.status === HTTP_STATUS.NO_CONTENT) {
                successAndNoContentHandler(
                    dispatch,
                    response,
                    teamId,
                    rolesIdsToLoad || [roleId],
                    toastSuccessMessage
                );
            } else {
                dispatch({
                    errorMessage: response?.error?.response?.data ?? '',
                    statusCode: response.error.response?.status,
                    statusText: response.error.response?.statusText,
                    type: REMOVE_USER_ROLE_ACTION.failed,
                });
            }
        },
        successOverride: ({ dispatch, response }) =>
            successAndNoContentHandler(
                dispatch,
                response,
                teamId,
                rolesIdsToLoad || [roleId],
                toastSuccessMessage
            ),
    });
