import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import RhFilesApi from 'api/generated/RhFilesApi';

export const ADD_TEAM_BENEFIT_RH_FILE_ACTION = 'ADD_ENROLLMENT_VERIFICATION_RH_FILE';

export const addTeamBenefitRhFile = (
    teamBenefitId: string,
    fileAndNames: FileAndName[],
    toastSuccessMessage?: string,
    redirectUrl?: string
) =>
    addRhFiles(
        ADD_TEAM_BENEFIT_RH_FILE_ACTION,
        async (files, names) => new RhFilesApi().addTeamBenefitRhFiles(teamBenefitId, files, names),
        fileAndNames,
        toastSuccessMessage,
        redirectUrl
    );
