import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION = 'SET_ENROLLMENT_STATUS_TO_ENROLLED';

export const setEnrollmentStatusToEnrolled = (userId: string | undefined) =>
    commonAction(
        async () => new UsersApi().setEnrollmentStatusToEnrolled(userId),
        SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION,
        {
            toastErrorMessage: 'Unable to set Enrollment Status',
            toastSuccessMessage: 'Enrollment Status Set to Enrolled',
        }
    );
