import { AppStoreThunkDispatch } from 'actions/commonAction';

export const UPLOAD_CENSUS_CLEAR = 'UPLOAD_CENSUS_CLEAR';

export const uploadCensusClearErrors = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        errorMessage: [],
        statusCode: '',
        type: UPLOAD_CENSUS_CLEAR,
    });
};
