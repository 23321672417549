import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';

export const BULK_SEND_RENEWAL_INVITES_ACTION = 'BULK_SEND_RENEWAL_INVITES';

export const bulkSendRenewalInvites = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().bulkSendRenewalInvites(teamId),
        BULK_SEND_RENEWAL_INVITES_ACTION,
        {
            successHook: ({ dispatch, getState }) =>
                dispatch(
                    listPagedUserProfiles(
                        teamId,
                        +getState().profileState.selectedYear,
                        getState().peopleState
                    )
                ),
            toastErrorMessage: 'Failed to send renewal invites',
            toastSuccessMessage: 'Successfully sent renewal invites',
        }
    );
