import commonAction from 'actions/commonAction';
import { TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import TokenApi from 'api/generated/TokenApi';
import { push } from 'connected-react-router';
import HTTP_STATUS from 'constants/responseStatuses';
import { DASHBOARD_PATH } from 'routers/routes';
import { TokenService } from 'security/TokenService';

export const getTokenForUser = (userId: string | undefined) =>
    commonAction(async () => new TokenApi().getImpersonationTokenForUser(userId), TOKEN_ACTION, {
        successOverride: ({ dispatch, response }) => {
            TokenService.storeExistingTokenAndDecodeAndCacheToken(
                response.data.accessToken,
                response.data.refreshToken
            );
            dispatch(push(DASHBOARD_PATH));
        },
        toastErrorMessage: (response) => {
            if (response.error.response?.status === HTTP_STATUS.FORBIDDEN) {
                return 'You are not allowed to impersonate this user';
            }
            return 'Failed to impersonate user';
        },
    });
