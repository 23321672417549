import commonAction from 'actions/commonAction';
import NotesApi from 'api/generated/NotesApi';

export const DELETE_TEAM_NOTE_ACTION = 'DELETE_TEAM_NOTE';

export const deleteTeamNote = (noteId: string) =>
    commonAction(async () => new NotesApi().deleteTeamNote(noteId), DELETE_TEAM_NOTE_ACTION, {
        toastErrorMessage: 'Failed to delete note',
        toastSuccessMessage: 'Successfully deleted note',
    });
