import { clearTeamFinch } from 'actions/clear';
import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const GET_TEAM_PROFILE_ACTION = 'GET_TEAM_PROFILE';

export const getTeamProfile = (teamId: string, isCurrent = false) =>
    commonAction(async () => new TeamsApi().getProfile(teamId), GET_TEAM_PROFILE_ACTION, {
        additionalDispatchData: { isCurrent },
        successHook: ({ dispatch }) => {
            dispatch(clearTeamFinch());
        },
    });
