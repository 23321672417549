import commonAction from 'actions/commonAction';
import { getUserProfile } from 'actions/user/getUserProfile';
import { IInvite, Invite } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import Toast from 'components/Toast';
import { push } from 'connected-react-router';
import HTTP_STATUS from 'constants/responseStatuses';
import map from 'lodash/map';
import { hasValue, PartialBy } from 'utilities';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';

export const SEND_INVITES_ACTION = 'SEND_INVITES';

export const sendInvites = (
    invites: (PartialBy<IInvite, 'isAdvisor' | 'isTeamAdmin'> & {
        firstName?: string;
        userId?: string;
    })[],
    redirectUrl?: string,
    shouldRefreshProfile = false
) => {
    const isMoreThanOneInvite = invites.length > 1;
    const filteredInvites = map(
        invites,
        (invite) =>
            new Invite({
                email: invite.email,
                isAdvisor: invite.isAdvisor ?? false,
                isTeamAdmin: invite.isTeamAdmin ?? false,
                name: invite.firstName,
                teamId: invite.teamId,
            })
    ).filter((invite) => hasValue(invite.email));
    return commonAction(async () => new UsersApi().invite(filteredInvites), SEND_INVITES_ACTION, {
        customErrorMessage: 'Failed to send invites',
        nonSuccessOverride: ({ dispatch, response }) => {
            let error = `Failed to send user invite${isMoreThanOneInvite ? 's' : ''}`;
            if (response.error.response?.status === HTTP_STATUS.CONFLICT) {
                error =
                    'A user with this email already exists. You can use the Set Member as Team Admin action from their Actions menu';
            }
            Toast.error(error);
            dispatch({
                errorMessage: error,
                statusCode: response.error.response?.status,
                statusText: response.error.response?.statusText,
                type: SEND_INVITES_ACTION.failed,
            });
        },
        successHook: ({ dispatch, getState }) => {
            if (redirectUrl) {
                dispatch(push(redirectUrl));
                dispatch(
                    listPagedUserProfiles(
                        (invites[0] as IInvite).teamId,
                        +getState().profileState.selectedYear,
                        getState().peopleState
                    )
                );
            } else if (shouldRefreshProfile && !isMoreThanOneInvite) {
                const year = +getState().profileState.selectedYear;
                dispatch(getUserProfile(invites[0]?.userId, false, year));
            }
        },
        toastSuccessMessage: `User invite${isMoreThanOneInvite ? 's have' : ' has'} been sent`,
    });
};
