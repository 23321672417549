import 'api/interceptors';
import { handleError } from 'api/interceptors';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import LogRocket from 'logrocket';
import { AppStore } from 'reducers/appReducer';
import { INTERMEDIATE_TOKEN_KEY } from 'security/TokenService';
import { getStore } from 'store/accessor';
import { hasValue } from 'utilities/index';

const ONE_THOUSAND_MILLISECONDS = 1000;
export default class ClientBase {
    protected instance: AxiosInstance;
    private requestOptions?: Partial<AxiosRequestConfig>;

    constructor() {
        this.instance = Axios.create();
        this.instance.interceptors.response.use(
            (response) => response,
            async (error) => await handleError(error)
        );
    }

    public withRequestOptions(options: Partial<AxiosRequestConfig>) {
        this.requestOptions = options;

        return this;
    }

    protected getBaseUrl(defaultUrl: string) {
        const url = process.env['API_URL']?.slice(0, -1);
        return url || defaultUrl;
    }

    protected async transformOptions(options: AxiosRequestConfig) {
        if (hasValue(this.requestOptions)) {
            options = { ...options, ...this.requestOptions };
        }
        return new Promise((resolve) => {
            const store = getStore<AppStore>();
            const state = store?.getState();
            options.headers['Authorization'] = `Bearer ${state?.login.accessToken ??
                sessionStorage.getItem(INTERMEDIATE_TOKEN_KEY)}`;
            if (process.env['LOGROCKET_PROJECT_ID']) {
                let hasTimeoutRan = false;
                const timeout = setTimeout(() => {
                    hasTimeoutRan = true;
                    resolve(options);
                }, ONE_THOUSAND_MILLISECONDS);
                LogRocket.getSessionURL((sessionURL) => {
                    clearTimeout(timeout);
                    if (!hasTimeoutRan) {
                        options.headers['X-LogRocket-URL'] = sessionURL;
                        resolve(options);
                    }
                });
            } else {
                resolve(options);
            }
        });
    }

    protected async transformResult<T>(
        _url: string,
        response: AxiosResponse<T>,
        processor: (response: AxiosResponse<T>) => Promise<T>
    ): Promise<AxiosResponse<T>> {
        const data = await processor(response);
        return { ...response, data };
    }
}
