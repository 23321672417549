import commonAction from 'actions/commonAction';
import { TaskItems } from 'api/generated/enums';
import { CompletedTeamTask } from 'api/generated/models';
import TasksApi from 'api/generated/TasksApi';

export const SAVE_COMPLETED_TEAM_TASK_ACTION = 'SAVE_COMPLETED_TEAM_TASK';

export const saveCompletedTeamTask = (taskItemId: TaskItems, teamId: string) =>
    commonAction(
        async () =>
            new TasksApi().saveCompletedTeamTask(new CompletedTeamTask({ taskItemId, teamId })),
        SAVE_COMPLETED_TEAM_TASK_ACTION
    );
