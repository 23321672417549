import commonAction from 'actions/commonAction';
import { SendSurveyRequest, UserProfile } from 'api/generated/models';
import SurveyApi from 'api/generated/SurveyApi';
import Toast from 'components/Toast';

export const SEND_SURVEY_EMAIL_ACTION = 'SEND_SURVEY_EMAIL';

export const sendSurveyEmail = (userProfile: UserProfile) => {
    const toastId = Toast.success(`Sending survey to ${userProfile.user?.firstName}...`);
    const errorMessage = `Failed to send survey to ${userProfile.user?.firstName}`;
    return commonAction(
        async () =>
            new SurveyApi().sendMemberSurveyEmail(
                new SendSurveyRequest({ userId: userProfile.user?.userId as string })
            ),
        SEND_SURVEY_EMAIL_ACTION,
        {
            customErrorMessage: errorMessage,
            successHook: () =>
                Toast.update(toastId, {
                    render: `Survey sent to ${userProfile.user?.firstName}`,
                }),
            toastErrorMessage: errorMessage,
        }
    );
};
