import { CancelToken } from 'axios';
import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_USER_PROFILES_METADATA_ACTION = 'GET_USER_PROFILES_METADATA_ACTION';

export const getUserProfilesMetadata = (teamId: string, year: number, token?: CancelToken) =>
    commonAction(
        async () => new UsersApi().getUserProfileMetadata(teamId, year, token),
        GET_USER_PROFILES_METADATA_ACTION
    );
