import commonAction from 'actions/commonAction';
import { SignUp } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { push } from 'connected-react-router';
import { SIGN_UP_PATH } from 'routers/routes';
import { TokenService } from 'security/TokenService';

export const SIGNUP_ACTION = 'SIGNUP';

export const signUp = (
    firstName: string,
    lastName: string,
    email: string,
    password: string | undefined,
    token: string
) =>
    commonAction(
        async () =>
            new UsersApi().signUp(new SignUp({ email, firstName, lastName, password, token })),
        SIGNUP_ACTION,
        {
            nonSuccessOverride: ({ dispatch, response }) => {
                const action = {
                    errorMessage: undefined as string | undefined,
                    statusCode: response.error.response?.status,
                    statusText: response.error.response?.statusText,
                    type: SIGNUP_ACTION.failed,
                };
                if (action.statusCode === 409) {
                    action.errorMessage = 'A user already exists with that email.';
                } else if (action.statusCode == 410) {
                    action.errorMessage =
                        'Your sign up link has expired, please contact your administrator.';
                } else {
                    action.errorMessage = 'An error occurred creating user.';
                }
                dispatch(action);
            },
            successOverride: ({ dispatch, response }) => {
                TokenService.decodeAndCacheToken(
                    response.data.accessToken,
                    response.data.refreshToken
                );
                dispatch(push(`${SIGN_UP_PATH}?complete`));
            },
        }
    );
