import commonAction from 'actions/commonAction';
import { TeamActionRequest } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const TRANSITION_TO_READY_FOR_MEMBER_LAUNCH_ACTION = 'TRANSITION_TO_READY_FOR_MEMBER_LAUNCH';

export const transitionToReadyForMemberLaunch = (teamId: string) =>
    commonAction(
        async () =>
            new TeamsApi().transitionTeamToReadyForMemberLaunch(new TeamActionRequest({ teamId })),
        TRANSITION_TO_READY_FOR_MEMBER_LAUNCH_ACTION
    );
