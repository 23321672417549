import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS =
    'GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS';

export const getSubmittedExpenseAssignableReimbursementReports = (
    submittedExpenseGlobalId: string
) =>
    commonAction(
        async () => new SubmittedExpensesApi().getAssignableReports(submittedExpenseGlobalId),
        GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS
    );
