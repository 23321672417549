import commonAction from 'actions/commonAction';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';

export const SEND_ENROLLMENT_VERIFICATION_REMINDER_ACTION = 'SEND_ENROLLMENT_VERIFICATION_REMINDER';

export const sendEnrollmentVerificationReminder = (userId: string) =>
    commonAction(
        async () => new EnrollmentVerificationsApi().sendReminder(userId),
        SEND_ENROLLMENT_VERIFICATION_REMINDER_ACTION,
        {
            toastErrorMessage: 'Unable to send reminder to user',
            toastSuccessMessage: 'Reminder sent to user',
        }
    );
