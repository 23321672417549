import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const GET_TEAM_PATHWAY_BLUEPRINTS_ACTION = 'GET_TEAM_PATHWAY_BLUEPRINTS_ACTION';

export const getTeamPathwayBlueprints = (teamId: string | undefined) =>
    commonAction(
        async () => new PathwayBlueprintApi().listPathwayBlueprints(teamId),
        GET_TEAM_PATHWAY_BLUEPRINTS_ACTION
    );
