import commonAction from 'actions/commonAction';
import { CopyTeamInfoRequest } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const COPY_TEAM_OPERATIONS_INFO_ACTION = 'COPY_TEAM_OPERATIONS_INFO';

export const copyTeamOperationsInfo = (teamId: string, sourceYear: number, targetYear: number) => {
    const copyInfoRequest = new CopyTeamInfoRequest({
        sourceYear,
        targetYear,
        teamId,
    });
    return commonAction(
        async () => new TeamsApi().copyOperationsInfo(copyInfoRequest),
        COPY_TEAM_OPERATIONS_INFO_ACTION,
        {
            toastErrorMessage: `Unable to copy the team operations info from ${sourceYear} to ${targetYear}`,
            toastSuccessMessage: `Operations Info copied from ${sourceYear} to ${targetYear} successfully`,
        }
    );
};
