import commonAction from 'actions/commonAction';
import { ITeamPlan, TeamPlan } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const ADD_TEAM_PLAN_ACTION = 'ADD_TEAM_PLAN';

export const addTeamPlan = (parentTeamPlan: ITeamPlan) => {
    const teamPlan = new TeamPlan(parentTeamPlan);
    return commonAction(async () => new TeamsApi().addTeamPlan(teamPlan), ADD_TEAM_PLAN_ACTION);
};
