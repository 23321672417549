import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { TEAMS_BENEFITS_PATH } from 'routers/routes';
export const DELETE_TEAM_BENEFIT_ACTION = 'DELETE_TEAM_BENEFIT';

export const deleteTeamBenefit = (id: string, teamId: string) =>
    commonAction(async () => new TeamsApi().deleteTeamBenefit(id), DELETE_TEAM_BENEFIT_ACTION, {
        successHook({ dispatch }) {
            const teamBenefitsPath = generatePath(TEAMS_BENEFITS_PATH, { teamId: teamId });
            dispatch(push(teamBenefitsPath));
        },
        toastErrorMessage: 'Failed to delete team benefit',
        toastSuccessMessage: 'Team benefit deleted',
    });
