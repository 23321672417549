import commonAction from 'actions/commonAction';
import { CategoryIds } from 'api/generated/enums';
import RhFilesApi from 'api/generated/RhFilesApi';

export const GET_RH_FILES_FOR_USER_ACTION = 'GET_RH_FILES_FOR_USER';

export const getRhFilesForUser = (
    userId: string,
    rhFileCategoryId: CategoryIds,
    includeDefault: boolean
) =>
    commonAction(
        async () => new RhFilesApi().getRhFilesForUser(userId, rhFileCategoryId, includeDefault),
        GET_RH_FILES_FOR_USER_ACTION
    );
