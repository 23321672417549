import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const SET_ACTIVE_WAGEUP_NOTE_ACTION = 'SET_ACTIVE_WAGEUP_NOTE';

export const setActiveWageUpNote = (activeWageUpId: string | undefined, note: string) =>
    commonAction(
        async () => new PathwayBlueprintApi().setActiveWageUpNote(activeWageUpId, note),
        SET_ACTIVE_WAGEUP_NOTE_ACTION,
        {
            toastErrorMessage: 'Failed to update Active Wage+ Note',
            toastSuccessMessage: 'Active Wage+ Note successfully updated',
        }
    );
