import commonAction from 'actions/commonAction';
import NotesApi from 'api/generated/NotesApi';

export const TOGGLE_ARCHIVE_TEAM_NOTE_ACTION = 'TOGGLE_ARCHIVE_TEAM_NOTE';

export const toggleArchiveTeamNote = (noteId: string, isArchived: boolean) =>
    commonAction(
        async () => new NotesApi().toggleTeamNoteArchived(noteId),
        TOGGLE_ARCHIVE_TEAM_NOTE_ACTION,
        {
            toastErrorMessage: `Failed to ${isArchived ? 'un-archive' : 'archive'} note`,
            toastSuccessMessage: `Successfully ${isArchived ? 'un-archived' : 'archived'} note`,
        }
    );
