import commonAction from 'actions/commonAction';
import CustomQuestionsApi from 'api/generated/CustomQuestionsApi';

export const LIST_CUSTOM_QUESTIONS_ACTION = 'LIST_CUSTOM_QUESTIONS';

export const listCustomQuestions = (teamId: string) =>
    commonAction(
        async () => new CustomQuestionsApi().listQuestions(teamId),
        LIST_CUSTOM_QUESTIONS_ACTION,
        {
            toastErrorMessage: 'Unable to retrieve questions',
        }
    );
