/* !!! THIS FILE IS AUTO-GENERATED DO NOT MAKE MANUAL CHANGES !!! */
export const ROLE_IDS = {
    ADVISOR: '240dc25a-df72-4194-9094-3370ab2bc00a',
    ADVISOR_ADMIN: '39fab2ca-1fde-4f74-948a-96636ae71995',
    CCM_ADMIN: 'f0faf0e0-8191-4404-ba76-8bff599bc6da',
    ENROLLMENT_CENSUS_MANAGER: '97a265f1-be15-40ea-85e3-087837e3ca2e',
    FINANCE: '3fed34a6-af25-4451-8e16-d48e92434b5b',
    HR_ADVISOR: 'af70f3a2-7286-44c3-b3b9-4fa8e8067959',
    MEMBER: '09435666-99e3-4058-9e58-29ccb9f5290c',
    PARTNER: '2565e461-ca3b-4499-9311-c523a99a3ed5',
    PARTNER_ADMIN: 'c15af5c1-217c-4ced-a70e-6dbc38d2e40d',
    RH_FULL_ADMIN: 'e9a321e9-b4f9-4241-898c-37b51d4ef5a4',
    RH_TEAM_MEMBER: '6a124b29-f286-4a6d-a048-47a8d8ec2dc9',
    TEAM_ADMIN: '6a564563-81af-48a3-be3e-2fd027034cb4',
    TEAM_PAYROLL_ADMIN: 'd3592f4b-4840-4522-b883-2e97c72a8563',
} as const;
const roleGuids = Object.values(ROLE_IDS);
export type RoleIds = typeof roleGuids[number];
