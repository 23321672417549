import commonAction from 'actions/commonAction';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';

export const COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA_ACTION = 'COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA';

export const copyTeamBenefitManageMemberData = (
    sourceTermDetailId: string,
    targetTermDetailId: string
) =>
    commonAction(
        async () =>
            new TeamBenefitsApi().copyTeamBenefitManageMemberData(
                sourceTermDetailId,
                targetTermDetailId
            ),
        COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA_ACTION,
        {
            toastErrorMessage: 'Failed to copy household member data',
            toastSuccessMessage: 'Household member data successfully copied',
        }
    );
