import commonAction from 'actions/commonAction';
import AdpApi from 'api/generated/AdpApi';

export const SYNC_USER_WITH_ADP = 'SYNC_USER_WITH_ADP';

export const syncUserWithAdp = (userId: string | undefined) =>
    commonAction(async () => new AdpApi().syncUser(userId), SYNC_USER_WITH_ADP, {
        toastErrorMessage: 'Failed to sync with ADP',
        toastSuccessMessage: 'Starting sync with ADP',
    });
