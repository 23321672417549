import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const BULK_SET_USE_PREMIUM_TAX_CREDITS_ACTION = 'BULK_SET_USE_PREMIUM_TAX_CREDITS';

export const bulkSetUsePremiumTaxCredits = (
    teamId: string,
    requestYear: number,
    usePremiumTaxCredits: boolean
) =>
    commonAction(
        async () =>
            new TeamsApi().bulkSetUsePremiumTaxCredits(teamId, requestYear, usePremiumTaxCredits),
        BULK_SET_USE_PREMIUM_TAX_CREDITS_ACTION,
        {
            toastErrorMessage:
                'Unable to update Use Premium Tax Credits value for all team members.',
            toastSuccessMessage:
                'Successfully updated Use Premium Tax Credits value for all team members.',
        }
    );
