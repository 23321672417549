import commonAction from 'actions/commonAction';
import TaskFlowsApi from 'api/generated/TaskFlowsApi';
import { TaskStatuses } from 'api/generated/enums';
import { EditTaskStatusRequest } from 'api/generated/models';
export const EDIT_TASK_STATUS_ACTION = 'EDIT_TASK_STATUS';

export const editTaskStatus = (userFlowTaskStatusGlobalId: string, taskStatusId: TaskStatuses) =>
    commonAction(
        async () =>
            new TaskFlowsApi().editTaskStatus(
                new EditTaskStatusRequest({
                    taskStatusId,
                    userFlowTaskStatusGlobalId,
                })
            ),
        EDIT_TASK_STATUS_ACTION
    );
