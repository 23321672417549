import commonAction from 'actions/commonAction';
import SurveyApi from 'api/generated/SurveyApi';

export const GET_SURVEY_INFO_FOR_USER_ACTION = 'GET_SURVEY_INFO_FOR_USER';

export const getSurveyInfoForUser = (userId: string) =>
    commonAction(
        async () => new SurveyApi().getSurveyInfoForUser(userId),
        GET_SURVEY_INFO_FOR_USER_ACTION,
        {
            toastErrorMessage: 'Failed to get survey info for user',
        }
    );
