import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';

export const TOGGLE_PAYROLL_REPORT_LOCKED_ACTION = 'TOGGLE_PAYROLL_REPORT_LOCKED';

export const togglePayrollReportLocked = (
    payrollReportId: string | undefined,
    lockingPrefix: '' | 'un'
) =>
    commonAction(
        async () => new PayrollReportsApi().togglePayrollReportLocked(payrollReportId),
        TOGGLE_PAYROLL_REPORT_LOCKED_ACTION,
        {
            toastErrorMessage: `Error ${lockingPrefix}locking Payroll Report`,
            toastSuccessMessage: `Payroll Report successfully ${lockingPrefix}locked`,
        }
    );
