import commonAction from 'actions/commonAction';
import { HouseholdMemberDto, IHouseholdMemberDto } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const ADD_HOUSEHOLD_MEMBER_ACTION = 'ADD_HOUSEHOLD_MEMBER';

export const addHouseholdMember = (householdMember: IHouseholdMemberDto) =>
    commonAction(
        async () => new UsersApi().addHouseholdMember(new HouseholdMemberDto(householdMember)),
        ADD_HOUSEHOLD_MEMBER_ACTION,
        {
            toastErrorMessage: 'Unable to add the household member',
            toastSuccessMessage: 'Successfully added household member',
        }
    );
