import commonAction from 'actions/commonAction';
import HrsApi from 'api/generated/HrsApi';

export const SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES = 'SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES';

export const setHrsBenefitsEligibilityClassCodes = (teamId: string, hrsProvider: number, classCodes: string[]) =>
    commonAction(
        async () => new HrsApi().updateHrsBenefitsEligibilityClassCodesForTeam(teamId, hrsProvider, classCodes),
        SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES,
        {
            toastErrorMessage: 'Failed to update ADP Benefits Eligibility Class Codes',
            toastSuccessMessage: 'Successfully updated ADP Benefits Eligibility Class Codes',
        }
    );
