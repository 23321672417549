import commonAction from 'actions/commonAction';
import { RelationshipTypeIds } from 'api/generated/enums';
import { EditTeamRelationshipRequest } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const EDIT_TEAM_RELATIONSHIP_ACTION = 'EDIT_TEAM_RELATIONSHIP';

export const editTeamRelationship = (
    teamId: string,
    userId: string,
    relationshipTypeId: RelationshipTypeIds
) =>
    commonAction(
        async () =>
            new TeamsApi().editTeamRelationship(
                teamId,
                new EditTeamRelationshipRequest({ relationshipTypeId, userId })
            ),
        EDIT_TEAM_RELATIONSHIP_ACTION,
        {
            toastErrorMessage: 'Unable to update representative',
        }
    );
