import commonAction from 'actions/commonAction';
import { Note } from 'api/generated/models';
import NotesApi from 'api/generated/NotesApi';

export const ADD_USER_NOTE_ACTION = 'ADD_USER_NOTE';

export const addUserNote = (note: Note) =>
    commonAction(async () => new NotesApi().addUserNote(note), ADD_USER_NOTE_ACTION, {
        toastErrorMessage: 'Failed to add note',
        toastSuccessMessage: 'Successfully added note',
    });
