import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION = 'SET_CUSTOM_PROJECTED_REIMBURSEMENT';

export const setCustomProjectedReimbursement = (
    pathwayUserId: string,
    customProjectedReimbursement: number | undefined,
    customProjectedReimbursementNote: string | undefined
) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().setCustomProjectedReimbursement(
                pathwayUserId,
                customProjectedReimbursement,
                customProjectedReimbursementNote
            ),
        SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION,
        {
            toastErrorMessage: 'Failed to update Projected Reimbursement',
            toastSuccessMessage: 'Projected Reimbursement successfully updated',
        }
    );
