import commonAction from 'actions/commonAction';
import MediShareApi from 'api/generated/MediShareApi';
import HTTP_STATUS from 'constants/responseStatuses';

export const GET_MEDISHARE_PLANS_FOR_USER_ACTION = 'GET_MEDISHARE_PLANS_FOR_USER';

export const getMediSharePlansAndRatesForUser = (
    userId: string,
    year: number | undefined,
    useNeedsCoverage: boolean,
    excludingMembers: string[],
    actionType = GET_MEDISHARE_PLANS_FOR_USER_ACTION
) =>
    commonAction(
        async () =>
            new MediShareApi().getPlans(userId, year, useNeedsCoverage, excludingMembers ?? []),
        actionType,
        {
            additionalDispatchData: { userId },
            nonSuccessOverride: ({ dispatch, response }) => {
                if (response.status === HTTP_STATUS.NO_CONTENT) {
                    dispatch({
                        errorMessage: '',
                        statusCode: HTTP_STATUS.NO_CONTENT,
                        statusText: '',
                        type: actionType.failed,
                    });
                } else {
                    dispatch({
                        errorMessage: response?.error?.response?.data ?? '',
                        statusCode: response.error.response?.status,
                        statusText: response.error.response?.status,
                        type: actionType.failed,
                    });
                }
            },
        }
    );
