import commonAction from 'actions/commonAction';
import RhFilesApi from 'api/generated/RhFilesApi';

export const TOGGLE_TEAM_RH_FILE_SHARED_ACTION = 'TOGGLE_TEAM_RH_FILE_SHARED';

export const toggleTeamRhFileShared = (teamId: string, rhFileId: string) =>
    commonAction(
        async () => new RhFilesApi().toggleTeamRhFileShared(rhFileId, teamId),
        TOGGLE_TEAM_RH_FILE_SHARED_ACTION,
        {
            toastErrorMessage: 'Failed to toggle shared status of file',
            toastSuccessMessage: 'Successfully toggle shared status of file',
        }
    );
