import commonAction from 'actions/commonAction';
import MemberTermsApi from 'api/generated/MemberTermsApi';

export const CREATE_PDF_AND_UPLOAD_TO_MEMBER_FILES_ACTION = 'CREATE_PDF_AND_UPLOAD_TO_MEMBER_FILES';

export const createPdfAndUploadToMemberFiles = (userId: string, signature: string) =>
    commonAction(
        async () => new MemberTermsApi().createPdfAndUploadToMemberFiles(userId, signature),
        CREATE_PDF_AND_UPLOAD_TO_MEMBER_FILES_ACTION
    );
