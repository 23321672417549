import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const COMPLETE_MEMBER_TASK_LIST_ACTION = 'COMPLETE_MEMBER_TASK_LIST';

export const completeMemberTaskList = (userId: string, year: number | undefined) =>
    commonAction(
        async () => new UsersApi().memberTaskListComplete(userId, year),
        COMPLETE_MEMBER_TASK_LIST_ACTION
    );
