import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const GET_PATHWAY_DATA_FOR_USER_ACTION = 'GET_PATHWAY_DATA_FOR_USER';

export const getPathwayDataForUser = (userId: string | undefined, year: number | undefined) =>
    commonAction(
        async () => new PathwayBlueprintApi().getPathwayDataForUser(userId, year),
        GET_PATHWAY_DATA_FOR_USER_ACTION
    );
