import commonAction from 'actions/commonAction';
import { Drug, IDrug } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const SET_DRUGS_FOR_HOUSEHOLD_MEMBER_ACTION = 'SET_DRUGS_FOR_HOUSEHOLD_MEMBER';

export const setDrugsForHouseholdMember = (
    householdMemberId: string,
    year: number,
    drugs: IDrug[]
) =>
    commonAction(
        async () =>
            new UsersApi().setDrugsForHouseholdMember(
                householdMemberId,
                year,
                drugs.map((x) => new Drug(x))
            ),
        SET_DRUGS_FOR_HOUSEHOLD_MEMBER_ACTION,
        {
            additionalDispatchData: { entityId: householdMemberId },
        }
    );
