import commonAction from 'actions/commonAction';
import { INote, Note } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const EDIT_USER_NOTE_ACTION = 'EDIT_USER_NOTE';

export const editUserNote = (
    userId: string | undefined,
    noteText: string,
    noteId: string | undefined
) =>
    commonAction(
        async () => new UsersApi().editNote(new Note({ noteId, noteText, userId } as INote)),
        EDIT_USER_NOTE_ACTION
    );
