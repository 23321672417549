import { AppStoreThunkDispatch } from 'actions/commonAction';

export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';

export const getStates = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        states: [
            {
                abbrev: 'AL',
                name: 'Alabama',
            },
            {
                abbrev: 'AK',
                name: 'Alaska',
            },
            {
                abbrev: 'AS',
                name: 'American Samoa',
            },
            {
                abbrev: 'AZ',
                name: 'Arizona',
            },
            {
                abbrev: 'AR',
                name: 'Arkansas',
            },
            {
                abbrev: 'CA',
                name: 'California',
            },
            {
                abbrev: 'CO',
                name: 'Colorado',
            },
            {
                abbrev: 'CT',
                name: 'Connecticut',
            },
            {
                abbrev: 'DE',
                name: 'Delaware',
            },
            {
                abbrev: 'DC',
                name: 'District of Columbia',
            },
            {
                abbrev: 'FL',
                name: 'Florida',
            },
            {
                abbrev: 'GA',
                name: 'Georgia',
            },
            {
                abbrev: 'GU',
                name: 'Guam',
            },
            {
                abbrev: 'HI',
                name: 'Hawaii',
            },
            {
                abbrev: 'ID',
                name: 'Idaho',
            },
            {
                abbrev: 'IL',
                name: 'Illinois',
            },
            {
                abbrev: 'IN',
                name: 'Indiana',
            },
            {
                abbrev: 'IA',
                name: 'Iowa',
            },
            {
                abbrev: 'KS',
                name: 'Kansas',
            },
            {
                abbrev: 'KY',
                name: 'Kentucky',
            },
            {
                abbrev: 'LA',
                name: 'Louisiana',
            },
            {
                abbrev: 'ME',
                name: 'Maine',
            },
            {
                abbrev: 'MD',
                name: 'Maryland',
            },
            {
                abbrev: 'MA',
                name: 'Massachusetts',
            },
            {
                abbrev: 'MI',
                name: 'Michigan',
            },
            {
                abbrev: 'MN',
                name: 'Minnesota',
            },
            {
                abbrev: 'MS',
                name: 'Mississippi',
            },
            {
                abbrev: 'MO',
                name: 'Missouri',
            },
            {
                abbrev: 'MT',
                name: 'Montana',
            },
            {
                abbrev: 'NE',
                name: 'Nebraska',
            },
            {
                abbrev: 'NV',
                name: 'Nevada',
            },
            {
                abbrev: 'NH',
                name: 'New Hampshire',
            },
            {
                abbrev: 'NJ',
                name: 'New Jersey',
            },
            {
                abbrev: 'NM',
                name: 'New Mexico',
            },
            {
                abbrev: 'NY',
                name: 'New York',
            },
            {
                abbrev: 'NC',
                name: 'North Carolina',
            },
            {
                abbrev: 'ND',
                name: 'North Dakota',
            },
            {
                abbrev: 'MP',
                name: 'Northern Mariana Islands',
            },
            {
                abbrev: 'OH',
                name: 'Ohio',
            },
            {
                abbrev: 'OK',
                name: 'Oklahoma',
            },
            {
                abbrev: 'OR',
                name: 'Oregon',
            },
            {
                abbrev: 'PA',
                name: 'Pennsylvania',
            },
            {
                abbrev: 'PR',
                name: 'Puerto Rico',
            },
            {
                abbrev: 'RI',
                name: 'Rhode Island',
            },
            {
                abbrev: 'SC',
                name: 'South Carolina',
            },
            {
                abbrev: 'SD',
                name: 'South Dakota',
            },
            {
                abbrev: 'TN',
                name: 'Tennessee',
            },
            {
                abbrev: 'TX',
                name: 'Texas',
            },
            {
                abbrev: 'UM',
                name: 'U.S. Minor Outlying Islands',
            },
            {
                abbrev: 'VI',
                name: 'U.S. Virgin Islands',
            },
            {
                abbrev: 'UT',
                name: 'Utah',
            },
            {
                abbrev: 'VT',
                name: 'Vermont',
            },
            {
                abbrev: 'VA',
                name: 'Virginia',
            },
            {
                abbrev: 'WA',
                name: 'Washington',
            },
            {
                abbrev: 'WV',
                name: 'West Virginia',
            },
            {
                abbrev: 'WI',
                name: 'Wisconsin',
            },
            {
                abbrev: 'WY',
                name: 'Wyoming',
            },
        ],
        type: GET_STATES_SUCCESS,
    });
};
