import commonAction from 'actions/commonAction';
import FinchApi from 'api/generated/FinchApi';

export const REGISTER_DEDUCTIONS_WITH_FINCH_ACTION = 'REGISTER_DEDUCTIONS_WITH_FINCH_ACTION';

export const registerDeductionsWithFinch = (teamId: string, successHook: () => void) =>
    commonAction(
        async () => new FinchApi().registerDeductions(teamId),
        REGISTER_DEDUCTIONS_WITH_FINCH_ACTION,
        {
            successHook,
            toastErrorMessage: 'Failed to register deductions with Finch',
            toastSuccessMessage: 'Deductions registration queued with Finch',
        }
    );
