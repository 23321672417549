import commonAction from 'actions/commonAction';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const DELETE_USER_RH_FILE_ACTION = 'DELETE_USER_RH_FILE';

export const deleteUserRhFile = ({ id }: IRhFile) =>
    commonAction(async () => new RhFilesApi().deleteUserRhFile(id), DELETE_USER_RH_FILE_ACTION, {
        customErrorMessage: 'Failed to delete file',
        toastErrorMessage: 'Failed to delete file',
        toastSuccessMessage: 'File deleted',
    });
