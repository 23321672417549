import commonAction from 'actions/commonAction';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';
import { AddEnrollmentVerification, IAddEnrollmentVerification } from 'api/generated/models';

export const ADD_ENROLLMENT_VERIFICATION_ACTION = 'ADD_ENROLLMENT_VERIFICATION';

export const addEnrollmentVerification = (
    enrollmentVerification: Partial<IAddEnrollmentVerification>
) =>
    commonAction(
        async () =>
            new EnrollmentVerificationsApi().add(
                new AddEnrollmentVerification(enrollmentVerification as IAddEnrollmentVerification)
            ),
        ADD_ENROLLMENT_VERIFICATION_ACTION,
        {
            toastErrorMessage: 'Unable to add enrollment verification',
        }
    );
