import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const UPDATE_WAIVING_TEAM_MEMBERS_ACTION = 'UPDATE_WAIVING_TEAM_MEMBERS';

export const updateWaivingMembers = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().updateWaivingMembers(teamId),
        UPDATE_WAIVING_TEAM_MEMBERS_ACTION,
        {
            toastErrorMessage: 'Failed to update waiving members',
            toastSuccessMessage: 'Successfully updated waiving members',
        }
    );
