import commonAction from 'actions/commonAction';
import { listPagedTeamProfiles } from 'actions/team/listPagedTeamProfiles';
import TeamsApi from 'api/generated/TeamsApi';

export const DELETE_TEAM_ACTION = 'DELETE_TEAM';

export const deleteTeam = (teamId: string | undefined) =>
    commonAction(async () => new TeamsApi().delete(teamId), DELETE_TEAM_ACTION, {
        successHook: ({ dispatch, getState }) => {
            dispatch(listPagedTeamProfiles(getState().teamsState));
        },
        toastErrorMessage: 'Unable to delete team',
        toastSuccessMessage: 'Team has been deleted',
    });
