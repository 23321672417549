import commonAction from 'actions/commonAction';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';
import { IAddEnrollmentVerification } from 'api/generated/models';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_ENROLLMENT_VERIFICATION_ACTION = 'PATCH_ENROLLMENT_VERIFICATION';

export const patchEnrollmentVerification = (
    enrollmentVerificationId: string,
    enrollmentVerification: Partial<IAddEnrollmentVerification>
) => {
    const enrollmentVerificationPatch = objectToPatchOperationsArray(enrollmentVerification);
    return commonAction(
        async () =>
            new EnrollmentVerificationsApi().patch(
                enrollmentVerificationId,
                enrollmentVerificationPatch
            ),
        PATCH_ENROLLMENT_VERIFICATION_ACTION,
        {
            toastErrorMessage: 'Unable to update verification document',
        }
    );
};
