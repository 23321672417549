import commonAction from 'actions/commonAction';
import RhFilesApi from 'api/generated/RhFilesApi';

export const TOGGLE_USER_RH_FILE_SHARED_ACTION = 'TOGGLE_USER_RH_FILE_SHARED';

export const toggleUserRhFileShared = (userId: string, rhFileId: string) =>
    commonAction(
        async () => new RhFilesApi().toggleUserRhFileShared(rhFileId, userId),
        TOGGLE_USER_RH_FILE_SHARED_ACTION,
        {
            toastErrorMessage: 'Failed to toggle shared status of file',
            toastSuccessMessage: 'Successfully toggle shared status of file',
        }
    );
