import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const BULK_SET_ALLOW_SHOPPING_ACTION = 'BULK_SET_ALLOW_SHOPPING';

export const bulkSetAllowShopping = (teamId: string, requestYear: number, allowShopping: boolean) =>
    commonAction(
        async () => new TeamsApi().bulkSetAllowShopping(teamId, requestYear, allowShopping),
        BULK_SET_ALLOW_SHOPPING_ACTION,
        {
            toastErrorMessage: 'Unable to update Allow Shopping value for all team members',
            toastSuccessMessage: 'Successfully updated Allow Shopping value for all team members.',
        }
    );
