import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';
import { CancelToken } from 'axios';

export const GET_USER_PROFILES_ACTION = 'GET_USER_PROFILES';

export const getUserProfiles = (
    teamId: string,
    year?: number,
    includeInactive = true,
    token?: CancelToken
) =>
    commonAction(
        async () => new UsersApi().listProfiles(teamId, year, includeInactive, token),
        GET_USER_PROFILES_ACTION
    );
