import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const EDIT_FICA_RATE = 'EDIT_FICA_RATE';

export const editFicaRate = (taxDataDtoId: string, ficaTaxRate: number) =>
    commonAction(
        async () => new UsersApi().editFicaRate(taxDataDtoId, ficaTaxRate),
        EDIT_FICA_RATE,
        {
            toastErrorMessage: 'Unable to update the FICA rate',
            toastSuccessMessage: 'Successfully updated the FICA rate',
        }
    );
