import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import { AssignableReportOptions } from 'api/generated/enums';
import { ISubmittedExpenseDto } from 'api/generated/models';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const EDIT_SUBMITTED_EXPENSE_ACTION = 'EDIT_SUBMITTED_EXPENSE';

export const editSubmittedExpense = (
    submittedExpense: ISubmittedExpenseDto,
    newFilesAndNames: FileAndName[],
    rhFileIdsToDelete: string[],
    option?: AssignableReportOptions
) =>
    addRhFiles(
        EDIT_SUBMITTED_EXPENSE_ACTION,
        async (fileParameters, names, cancelToken) =>
            new SubmittedExpensesApi().editSubmittedExpense(
                submittedExpense.globalId,
                submittedExpense.teamId,
                submittedExpense.expenseStatus,
                submittedExpense.decisionSummary,
                submittedExpense.expenseAmount,
                submittedExpense.amountEligibleForReimbursement,
                submittedExpense.reimbursementToWages,
                submittedExpense.reimburseToHsa,
                submittedExpense.reimbursementReportGlobalId,
                option,
                submittedExpense.isRecurring,
                submittedExpense.isArchived,
                fileParameters,
                names,
                rhFileIdsToDelete,
                cancelToken
            ),
        newFilesAndNames,
        'Successfully edited submitted expense',
        undefined,
        undefined,
        'Unable to edit submitted expense'
    );
