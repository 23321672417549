import commonAction from 'actions/commonAction';
import { ITeamOperationsInfo } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const CREATE_OR_PATCH_TEAM_OPERATIONS_INFO_ACTION = 'EDIT_TEAM_OPERATIONS_INFO';

export const createOrPatchOperationsInfo = (
    teamId: string,
    year: number,
    operationsInfo: ITeamOperationsInfo
) => {
    const operationsInfoPatch = objectToPatchOperationsArray(operationsInfo);
    return commonAction(
        async () => new TeamsApi().createOrPatchOperationsInfo(teamId, year, operationsInfoPatch),
        CREATE_OR_PATCH_TEAM_OPERATIONS_INFO_ACTION,
        {
            toastErrorMessage: 'Unable to update the team operations info',
        }
    );
};
