import commonAction from 'actions/commonAction';
import { TeamActionRequest } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';

export const INVITE_USERS_FOR_TEAM_ACTION = 'INVITE_USERS_FOR_TEAM';

export const inviteUsersForTeam = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().inviteUsersForTeam(new TeamActionRequest({ teamId })),
        INVITE_USERS_FOR_TEAM_ACTION,
        {
            successHook: ({ dispatch, getState }) =>
                dispatch(
                    listPagedUserProfiles(
                        teamId,
                        +getState().profileState.selectedYear,
                        getState().peopleState
                    )
                ),
            toastErrorMessage: 'Unable to send invites',
            toastInitialMessage: 'Sending invites to members',
            toastSuccessMessage: 'Invites sent',
        }
    );
