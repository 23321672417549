import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const UPDATE_INCOME_VERIFIED_DATE_ACTION = 'UPDATE_INCOME_VERIFIED_DATE';

export const updateIncomeVerifiedDate = (memberVerifiedInfoId: string | undefined) =>
    commonAction(
        async () => new UsersApi().updateIncomeVerifiedDate(memberVerifiedInfoId),
        UPDATE_INCOME_VERIFIED_DATE_ACTION,
        {
            toastErrorMessage: 'Unable to update Income Verified Date',
            toastSuccessMessage: 'Income Verified Date has been updated',
        }
    );
