import commonAction from 'actions/commonAction';
import { saveCompletedTeamTask } from 'actions/task/saveCompletedTeamTask';
import { TaskItems } from 'api/generated/enums';
import { ILaunchDetails, LaunchDetails } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const ADD_LAUNCH_DETAILS_ACTION = 'ADD_LAUNCH_DETAILS';

export const addLaunchDetails = (launchDetails: ILaunchDetails) =>
    commonAction(
        async () => new TeamsApi().addLaunchDetails(new LaunchDetails(launchDetails)),
        ADD_LAUNCH_DETAILS_ACTION,
        {
            successHook: ({ dispatch }) =>
                dispatch(
                    saveCompletedTeamTask(TaskItems.CompleteLaunchDetails, launchDetails.teamId)
                ),
            toastErrorMessage: (response) =>
                `Unable to add launch details. ${response.error.response?.data}`,
        }
    );
