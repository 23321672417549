import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';
import { push } from 'connected-react-router';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';

export const UPLOAD_CENSUS_ACTION = 'UPLOAD_CENSUS';

export const uploadCensus = (
    teamId: string,
    censusFile: File,
    memberActiveDate: string,
    redirectUrl?: string
) =>
    commonAction(
        async () =>
            new UsersApi().uploadCensus(
                teamId,
                null,
                { data: censusFile, fileName: censusFile.name },
                memberActiveDate
            ),
        UPLOAD_CENSUS_ACTION,
        {
            successHook: ({ dispatch, getState }) => {
                const { selectedYear } = getState().profileState;
                dispatch(listPagedUserProfiles(teamId, +selectedYear, getState().peopleState));

                if (redirectUrl !== undefined) {
                    dispatch(push(redirectUrl));
                }
            },
            toastSuccessMessage: 'Census uploaded',
        }
    );
