import commonAction from 'actions/commonAction';
import { IRhFile, RhFile, User } from 'api/generated/models';
import { AxiosResponse } from 'axios';

type IRhFileUserProperty = keyof Pick<
    IRhFile,
    'archivedByUser' | 'createdByUser' | 'lastModifiedByUser'
>;
const setUser = (rhFile: IRhFile, property: IRhFileUserProperty) => {
    if (rhFile[property]) {
        rhFile[property] = new User(rhFile[property]);
    }
};

const setUserProperties = (rhFile: IRhFile, ...properties: IRhFileUserProperty[]) =>
    properties.forEach((x) => setUser(rhFile, x));

export const renameRhFile = (
    action: string,
    parentRenameRhFile: (rhFile: RhFile) => Promise<AxiosResponse<RhFile>>,
    rhFile: IRhFile
) => {
    const newRhFile = new RhFile(rhFile);
    setUserProperties(newRhFile, 'createdByUser', 'archivedByUser', 'lastModifiedByUser');
    return commonAction(async () => parentRenameRhFile(newRhFile), action, {
        toastErrorMessage: 'Failed to rename file',
        toastSuccessMessage: 'Successfully renamed file',
    });
};
