import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const TRANSITION_TO_TEAM_ADMIN_LAUNCH_ACTION = 'TRANSITION_TO_TEAM_ADMIN_LAUNCH';

export const transitionToTeamAdminLaunch = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().transitionTeamToTeamAdminLaunch(teamId),
        TRANSITION_TO_TEAM_ADMIN_LAUNCH_ACTION,
        {
            toastErrorMessage: 'Unable to move team to Team Admin Launch state',
        }
    );
