import commonAction from 'actions/commonAction';
import TaskFlowsApi from 'api/generated/TaskFlowsApi';

export const COMPLETE_All_ICHRA_ENROLLMENT_TASKS_ACTION = 'COMPLETE_All_ICHRA_ENROLLMENT_TASKS';

export const completeAllIchraEnrollmentTasks = (userId: string) =>
    commonAction(
        async () => new TaskFlowsApi().completeAllIchraEnrollmentTasks(userId),
        COMPLETE_All_ICHRA_ENROLLMENT_TASKS_ACTION,
        {
            toastErrorMessage: 'Failed to complete all Ichra Flow Tasks',
            toastSuccessMessage: 'Successfully completed all Ichra Flow Tasks',
        }
    );
