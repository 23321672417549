import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';
import { CancelToken } from 'axios';

export const GET_PAYROLL_REPORTS_FOR_YEAR_ACTION = 'GET_PAYROLL_REPORTS';

export const getPayrollReportsForYear = (teamId: string, year: number, token: CancelToken) =>
    commonAction(
        async () => new PayrollReportsApi().getPayrollReportsForYear(teamId, year, token),
        GET_PAYROLL_REPORTS_FOR_YEAR_ACTION,
        {
            toastErrorMessage: `Unable to get payroll reports for ${year}`,
        }
    );
