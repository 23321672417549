import commonAction from 'actions/commonAction';
import { getFavoriteTeams } from 'actions/favoriteTeams/getFavoriteTeams';
import FavoriteTeamsApi from 'api/generated/FavoriteTeamsApi';

export const ADD_FAVORITE_TEAM_ACTION = 'ADD_FAVORITE_TEAM';

export const addFavoriteTeam = (teamId: string) =>
    commonAction(
        async () => new FavoriteTeamsApi().addFavoriteTeam(teamId),
        ADD_FAVORITE_TEAM_ACTION,
        {
            successHook: ({ dispatch }) => {
                dispatch(getFavoriteTeams());
            },
        }
    );
