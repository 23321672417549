import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';
import Toast from 'components/Toast';
import { hasValue } from 'utilities';

export const TRANSITION_TO_CUSTOMER_ACTION = 'TRANSITION_TO_CUSTOMER';

export const transitionToCustomer = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().transitionTeamToCustomer(teamId),
        TRANSITION_TO_CUSTOMER_ACTION,
        {
            nonSuccessOverride: ({ dispatch, response }) => {
                const message = (response.error.response?.data as unknown) as string;
                if (hasValue(message)) {
                    Toast.error(message);
                }
                dispatch({ type: TRANSITION_TO_CUSTOMER_ACTION.failed });
            },
            toastSuccessMessage: 'Successfully set team to Customer status.',
        }
    );
