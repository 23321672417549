import commonAction from 'actions/commonAction';
import { IUser } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const SEND_RENEWAL_EMAIL_ACTION = 'SEND_RENEWAL_EMAIL';

export const sendRenewalEmail = (user: IUser | undefined) =>
    commonAction(
        async () => new UsersApi().sendRenewalEmail(user?.teamId, user?.userId ?? ''),
        SEND_RENEWAL_EMAIL_ACTION,
        {
            toastInitialMessage: `Sending renewal invite to ${user?.firstName}...`,
            toastSuccessMessage: `Renewal invite sent to ${user?.firstName}`,
        }
    );
