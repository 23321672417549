import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const COMPARE_VERIFIED_INFO = 'COMPARE_VERIFIED_INFO';

export const compareVerifiedInfo = (
    userId: string | undefined,
    sourceYear: number,
    destinationYear: number
) =>
    commonAction(
        async () => new UsersApi().compareMemberVerifiedInfo(userId, sourceYear, destinationYear),
        COMPARE_VERIFIED_INFO
    );
