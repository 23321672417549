import commonAction from 'actions/commonAction';
import SurveyApi from 'api/generated/SurveyApi';

export const GET_SURVEY_YEAR_FOR_USER_ACTION = 'GET_SURVEY_YEAR_FOR_USER';

export const getSurveyYearForUser = (userId: string | undefined) =>
    commonAction(
        async () => new SurveyApi().getSurveyYearForUser(userId),
        GET_SURVEY_YEAR_FOR_USER_ACTION
    );
