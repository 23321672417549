import commonAction from 'actions/commonAction';
import { IPathwayBlueprint } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_PATHWAY_BLUEPRINT_COSTS_ACTION = 'PATCH_PATHWAY_BLUEPRINT_COSTS';

export const patchPathwayBlueprintCosts = (
    pathwayBlueprintId: string,
    patch: Partial<IPathwayBlueprint>,
    toastMessageSubject: string
) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().patchPathwayBlueprintCosts(
                pathwayBlueprintId,
                objectToPatchOperationsArray(patch)
            ),
        PATCH_PATHWAY_BLUEPRINT_COSTS_ACTION,
        {
            toastErrorMessage: `Error updating ${toastMessageSubject}`,
            toastSuccessMessage: `${toastMessageSubject} successfully updated`,
        }
    );
