import commonAction from 'actions/commonAction';
import { Address, IAddress } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const EDIT_TEAM_ADDRESS_ACTION = 'EDIT_TEAM_ADDRESS';

export const editTeamAddress = (teamId: string, address: IAddress, isCurrent: boolean) =>
    commonAction(
        async () => new TeamsApi().editAddress(teamId, new Address(address)),
        EDIT_TEAM_ADDRESS_ACTION,
        {
            additionalDispatchData: { isCurrent },
            toastErrorMessage: 'Unable to update the address',
        }
    );
