import commonAction from 'actions/commonAction';
import { FileParameter } from 'api/generated/models';
import { AxiosResponse, CancelToken } from 'axios';
import Toast from 'components/Toast';
import { push } from 'connected-react-router';
import HTTP_STATUS from 'constants/responseStatuses';
import React from 'react';

export type FileAndName = {
    file: File;
    name: string;
};

export const addRhFiles = <T,>(
    action: string,
    apiMethod: (
        fileParameters: FileParameter[],
        names: string,
        cancelToken?: CancelToken
    ) => Promise<AxiosResponse<T>>,
    fileAndNames: FileAndName[],
    toastSuccessMessage?: string,
    redirectUrl?: string,
    additionalDispatchData = {},
    toastErrorMessage = 'Failed to upload file'
) => {
    const names = fileAndNames.reduce(
        (obj, fileAndName) => ({
            ...obj,
            [fileAndName.file.name]: fileAndName.name,
        }),
        {}
    );
    const fileParameters: FileParameter[] = fileAndNames.map((fileAndName) => ({
        data: fileAndName.file,
        fileName: fileAndName.file.name,
        prettyName: fileAndName.name,
    }));
    return commonAction(async () => apiMethod(fileParameters, JSON.stringify(names)), action, {
        additionalDispatchData,
        toastErrorMessage,
        toastSuccessMessage,
        nonSuccessHook: ({ response }) => {
            if (response?.error?.response?.status === HTTP_STATUS.BAD_REQUEST) {
                const errors = (response?.error?.response?.data as unknown) as string[];
                if (errors) {
                    Toast.error(
                        <React.Fragment>
                            {errors.map((x, idx) => (
                                <div className="text-break mb-2" key={idx}>
                                    {x}
                                </div>
                            ))}
                        </React.Fragment>
                    );
                }
            }
        },
        successHook: ({ dispatch }) => {
            if (redirectUrl) {
                dispatch(push(redirectUrl));
            }
        },
    });
};
