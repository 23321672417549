import commonAction from 'actions/commonAction';
import { AddressTypes } from 'api/generated/enums';
import { Address, IAddress } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const EDIT_HOUSEHOLD_MEMBER_HOME_ADDRESS_ACTION =
    'EDIT_HOUSEHOLD_MEMBER_HOME_ADDRESS_ACTION';
export const EDIT_HOUSEHOLD_MEMBER_MAILING_ADDRESS_ACTION =
    'EDIT_HOUSEHOLD_MEMBER_MAILING_ADDRESS_ACTION';

export const editHouseholdMemberAddress = (
    householdMemberId: string,
    year: number,
    address: IAddress,
    addressType: AddressTypes
) =>
    commonAction(
        async () =>
            new UsersApi().editHouseholdMemberAddress(
                householdMemberId,
                year,
                addressType,
                new Address(address)
            ),
        addressType === AddressTypes.Home
            ? EDIT_HOUSEHOLD_MEMBER_HOME_ADDRESS_ACTION
            : EDIT_HOUSEHOLD_MEMBER_MAILING_ADDRESS_ACTION,
        {
            additionalDispatchData: { householdMemberId },
            toastErrorMessage: 'Unable to update the address',
        }
    );
