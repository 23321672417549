import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import RhFilesApi from 'api/generated/RhFilesApi';

export const ADD_USER_RH_FILES_ACTION = 'ADD_USER_RH_FILES';

export const addUserRhFiles = (
    userId: string,
    fileAndNames: FileAndName[],
    toastSuccessMessage?: string,
    redirectUrl?: string
) =>
    addRhFiles(
        ADD_USER_RH_FILES_ACTION,
        async (files, names) => new RhFilesApi().addUserRhFiles(userId, files, names),
        fileAndNames,
        toastSuccessMessage,
        redirectUrl
    );
