import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';
import { County } from 'api/generated/models';
import { AxiosResponse } from 'axios';
import Toast from 'components/Toast';
import HTTP_STATUS from 'constants/responseStatuses';
import { ToastContent } from 'react-toastify';

export const GET_COUNTIES_ACTION = 'GET_COUNTIES';

const error = (message: ToastContent) => Toast.error(message);

type CustomCountyResponse = AxiosResponse<County[]> & {
    isTooShort: boolean;
};
export const getCounties = (zipCode: string, year: number) =>
    commonAction(
        async () => {
            if (zipCode.length == 5) {
                return new MarketplaceApi().listCountiesByZip(zipCode, year);
            }
            return Promise.resolve(({
                data: [],
                isTooShort: true,
                status: 200,
            } as unknown) as CustomCountyResponse);
        },
        GET_COUNTIES_ACTION,
        {
            nonSuccessHook: ({ response }) => {
                if (response.status !== HTTP_STATUS.UNAUTHORIZED) {
                    error('Unable to get counties, please re-enter Zip Code');
                }
            },
            successOverride: ({ dispatch, response }) => {
                if (
                    response.data?.length !== undefined &&
                    response.data?.length < 1 &&
                    !(response as CustomCountyResponse).isTooShort
                ) {
                    error(`${zipCode} is invalid. Please enter another zip code`);

                    dispatch({
                        errorMessage: `Zip Code ${zipCode} returned no counties`,
                        statusCode: '404',
                        type: GET_COUNTIES_ACTION.failed,
                    });
                } else {
                    dispatch({
                        data: response.data,
                        type: GET_COUNTIES_ACTION.success,
                    });
                }
            },
        }
    );
