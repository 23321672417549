import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS = 'GET_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS';

export const getSubmittedExpenseModalUtilizations = (userId: string, year: string) =>
    commonAction(
        async () =>
            new SubmittedExpensesApi().getMemberReimbursementUtilizations(userId, Number(year)),
        GET_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS
    );
