import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_HOUSEHOLD_ELIGIBILITY_ACTION = 'GET_HOUSEHOLD_ELIGIBILITY';

export const getHouseholdEligibility = (userId: string, year: number) =>
    commonAction(
        async () => new UsersApi().getHouseholdEligibility(userId, year),
        GET_HOUSEHOLD_ELIGIBILITY_ACTION
    );
