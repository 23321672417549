import { CLEAR_STATE } from 'actions/clear';
import { IToken, Token } from 'api/generated/models';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import HTTP_STATUS from 'constants/responseStatuses';
import get from 'lodash/get';
import has from 'lodash/has';
import { AppStore } from 'reducers/appReducer';
import { INTERMEDIATE_TOKEN_KEY, TokenService } from 'security/TokenService';
import { getStore } from 'store/accessor';
import { hasValue } from 'utilities/index';

const clearStateAndCache = () => {
    const store = getStore();
    store?.dispatch({
        type: CLEAR_STATE,
    });
    TokenService.clearTokenFromCache();
};

const getRefreshToken = async (token: IToken) => {
    try {
        return await axios.create().post(`${process.env['API_URL']}api/token.refreshToken`, token);
    } catch (error) {
        if ((error as AxiosError)?.response?.status === HTTP_STATUS.UNAUTHORIZED) {
            clearStateAndCache();
        }
        return (error as AxiosError).response;
    }
};

const handleGetRefreshTokenSuccess = async (
    error: AxiosError,
    tokenResponse: AxiosResponse<Token>
) => {
    TokenService.decodeAndCacheToken(
        tokenResponse.data.accessToken,
        tokenResponse.data.refreshToken
    );

    const retryCount: number = get(error.config, 'retryCount', 0);
    const maxRetryCount = 3;
    if (retryCount <= maxRetryCount) {
        const config: AxiosRequestConfig & { retryCount?: number } = { ...error.config };
        config.headers.Authorization = `Bearer ${tokenResponse.data.accessToken}`;
        config.retryCount = retryCount + 1;
        return axios(config);
    } else {
        clearStateAndCache();
    }
    return Promise.resolve();
};

export async function handleError(error: AxiosError) {
    if (error?.response?.status === HTTP_STATUS.UNAUTHORIZED) {
        if (has(error.response.headers, 'token-expired')) {
            const store = getStore<AppStore>();
            const { accessToken, refreshToken } = store?.getState().login ?? {};
            const intermediateToken = sessionStorage.getItem(INTERMEDIATE_TOKEN_KEY) as string;
            let tokenResponse;
            if (!hasValue(intermediateToken)) {
                tokenResponse = await getRefreshToken({
                    accessToken,
                    refreshToken,
                });
            }
            if (tokenResponse?.status === HTTP_STATUS.OK) {
                return handleGetRefreshTokenSuccess(error, tokenResponse);
            }
        } else if (!error?.request?.responseURL.endsWith('/api/Mfa.validateOtp')) {
            clearStateAndCache();
        }
    }
    return { error };
}
