import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const CREATE_RESET_REQUIRED_LOGIN_ACTION = 'CREATE_RESET_REQUIRED_LOGIN';

export const createResetRequiredLogin = (userId: string | undefined, dataYear: number) =>
    commonAction(
        async () => new UsersApi().createResetRequiredLogin(userId, dataYear),
        CREATE_RESET_REQUIRED_LOGIN_ACTION,
        {
            toastErrorMessage: 'Failed to create login',
            toastSuccessMessage: 'Login successfully created',
        }
    );
