import commonAction from 'actions/commonAction';
import { IUserUserRelationship, UserUserRelationship } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const ADD_USER_RELATIONSHIP_ACTION = 'ADD_USER_RELATIONSHIP';
export const EDIT_USER_RELATIONSHIP_ACTION = 'EDIT_USER_RELATIONSHIP';

export const setUserRelationship = (
    userRelationship: Partial<IUserUserRelationship>,
    isCurrent: boolean,
    {
        toastErrorMessage = '',
        toastSuccessMessage = '',
    }: { toastErrorMessage?: string; toastSuccessMessage?: string } = {}
) =>
    commonAction(
        async () =>
            new UsersApi().setUserRelationship(
                new UserUserRelationship(userRelationship as IUserUserRelationship)
            ),
        userRelationship.id ? EDIT_USER_RELATIONSHIP_ACTION : ADD_USER_RELATIONSHIP_ACTION,
        {
            toastErrorMessage,
            toastSuccessMessage,
            additionalDispatchData: { isCurrent },
        }
    );
