import commonAction from 'actions/commonAction';
import {
    ACCOUNT_IS_LOCKED_TEXT,
    INTERMEDIATE_TOKEN_ACTION,
    LOCKED_OUT_ERROR_TEXT,
} from 'actions/token/tokenActionUtility';
import MfaApi from 'api/generated/MfaApi';
import { push } from 'connected-react-router';
import { MFA_VERIFICATION_PATH } from 'routers/routes';
import { getStore } from 'store/accessor';

export const sendNewMfaVerification = () =>
    commonAction(async () => new MfaApi().sendNewOtp(), INTERMEDIATE_TOKEN_ACTION, {
        customErrorMessage: (response) => {
            const msg = (response.error.response?.data as unknown) as string;
            let errorText = msg ?? 'There was an unexpected error.  Please try again.';
            if (msg === ACCOUNT_IS_LOCKED_TEXT) {
                errorText = LOCKED_OUT_ERROR_TEXT;
            }
            return errorText;
        },
        successOverride: () => {
            const store = getStore();
            store?.dispatch(push(MFA_VERIFICATION_PATH));
        },
    });
