import commonAction from 'actions/commonAction';
import { Note } from 'api/generated/models';
import NotesApi from 'api/generated/NotesApi';

export const ADD_TEAM_NOTE_ACTION = 'ADD_TEAM_NOTE';

export const addTeamNote = (note: Note) =>
    commonAction(async () => new NotesApi().addTeamNote(note), ADD_TEAM_NOTE_ACTION, {
        toastErrorMessage: 'Failed to add note',
        toastSuccessMessage: 'Successfully added note',
    });
