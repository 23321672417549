import commonAction from 'actions/commonAction';
import { PlanListing } from 'api/generated/enums';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const SEARCH_PLANS_ACTION = 'SEARCH_PLANS';

export const searchMarketplacePlans = (
    stateAbbrev: string | undefined,
    zipCode: string | undefined,
    countyFips: string | undefined,
    year: number,
    listing = PlanListing.OnExchange
) =>
    commonAction(
        async () =>
            new MarketplaceApi().searchPlans(
                stateAbbrev,
                zipCode,
                countyFips,
                year || new Date().getUTCFullYear(),
                listing
            ),
        SEARCH_PLANS_ACTION,
        {
            toastErrorMessage: `Failed to retrieve plans for ${year}`,
        }
    );
