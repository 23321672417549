import commonAction from 'actions/commonAction';
import { ITeam } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
import { objectToPatchOperationsArray } from 'api/utilities';
import Toast from 'components/Toast';

export const PATCH_TEAM_ACTION = 'PATCH_TEAM';

export const patchTeam = (
    teamId: string | undefined,
    team: Partial<ITeam>,
    isCurrent: boolean,
    shouldThrow = false
) => {
    const teamPatch = objectToPatchOperationsArray(team);
    return commonAction(
        async () => new TeamsApi().patchTeam(teamId, teamPatch),
        PATCH_TEAM_ACTION,
        {
            additionalDispatchData: { isCurrent },
            nonSuccessOverride: ({ response }) => {
                const message = `Unable to update team. ${response.error.response?.data}`;
                Toast.error(message);
                if (shouldThrow) {
                    throw message;
                }
            },
        }
    );
};
