import commonAction from 'actions/commonAction';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';

export const GET_ALL_ENROLLMENT_VERIFICATION_FOR_USER_ACTION =
    'GET_ALL_ENROLLMENT_VERIFICATION_FOR_USER';

export const getAllEnrollmentVerificationForUser = (userId: string) =>
    commonAction(
        async () => new EnrollmentVerificationsApi().getAllForUser(userId),
        GET_ALL_ENROLLMENT_VERIFICATION_FOR_USER_ACTION,
        {
            toastErrorMessage: 'Unable to get verification documents',
        }
    );
