import { Operation } from 'api/generated/models';

type IPatchOperation<T> = {
    op?: 'replace';
    path?: string;
    value?: T[keyof T];
};
export const objectToPatchOperationsArray = <T extends object>(
    entity: T,
    shouldEvaluateRecursively = false,
    shouldAllowUndefinedValues = false,
    keyPrefix = ''
) => {
    let patchOperations: IPatchOperation<T>[] = [];
    Object.keys(entity).forEach((key) => {
        const path = `${keyPrefix}/${key}`;
        const value = entity[key as keyof T];

        if (typeof value === 'object' && value !== null && shouldEvaluateRecursively) {
            patchOperations = patchOperations.concat(
                objectToPatchOperationsArray(
                    (value as unknown) as object,
                    true,
                    false,
                    path
                ) as IPatchOperation<T>[]
            );
        } else if (
            value !== undefined ||
            shouldAllowUndefinedValues ||
            (key === 'spouseDateOfBirth' && value === undefined)
        ) {
            patchOperations.push({ path, value, op: 'replace' });
        }
    });
    return (patchOperations as unknown) as Operation[];
};
