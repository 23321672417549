import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_DEFAULT_MEMBER_DATA_YEAR_ACTION = 'GET_DEFAULT_MEMBER_DATA_YEAR';

export const getDefaultMemberDataYear = () =>
    commonAction(
        async () => new UsersApi().getDefaultMemberDataYear(),
        GET_DEFAULT_MEMBER_DATA_YEAR_ACTION
    );
