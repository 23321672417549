import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const UPLOAD_SUBMITTED_EXPENSES_ACTION = 'UPLOAD_SUBMITTED_EXPENSES';

export const uploadSubmittedExpenses = (submittedExpensesFile: File | undefined) =>
    commonAction(
        async () =>
            new SubmittedExpensesApi().importSubmittedExpenses(null, {
                data: submittedExpensesFile,
                fileName: submittedExpensesFile?.name as string,
            }),
        UPLOAD_SUBMITTED_EXPENSES_ACTION,
        {
            toastErrorMessage: `Failed to upload Submitted Expenses file ${submittedExpensesFile?.name}`,
            toastSuccessMessage: `Successfully started importing Submitted Expenses file ${submittedExpensesFile?.name}. An email will be sent once the import is complete`,
        }
    );
