import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { TEAMS_PATHWAY_BLUEPRINT_PATH } from 'routers/routes';

export const DUPLICATE_PATHWAY_BLUEPRINT_ACTION = 'DUPLICATE_PATHWAY_BLUEPRINT_ACTION';

export const duplicatePathwayBlueprint = (
    blueprintId: string | undefined,
    blueprintName: string | undefined
) =>
    commonAction(
        async () => new PathwayBlueprintApi().duplicatePathwayBlueprint(blueprintId, blueprintName),
        DUPLICATE_PATHWAY_BLUEPRINT_ACTION,
        {
            successHook: ({
                dispatch,
                response: {
                    data: { id, teamId },
                },
            }) =>
                dispatch(
                    push(
                        generatePath(
                            generatePath(TEAMS_PATHWAY_BLUEPRINT_PATH, {
                                teamId,
                                pathwayBlueprintId: id,
                            })
                        )
                    )
                ),
            toastErrorMessage: (response) =>
                `Unable to update duplicate pathway blueprint. ${response.error.response?.data}`,
            toastSuccessMessage: 'Pathway Blueprint has been duplicated',
        }
    );
