import commonAction from 'actions/commonAction';
import { ITeamBenefitTermDetail, TeamBenefitTermDetail } from 'api/generated/models';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';

export const ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION = 'ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL';

export const addOrEditTeamBenefitTermDetail = (
    teamBenefitId: string | undefined,
    benefitTermDetail: Partial<ITeamBenefitTermDetail> | undefined
) =>
    commonAction(
        async () =>
            new TeamBenefitsApi().addOrEditTeamBenefitTermDetail(
                teamBenefitId,
                new TeamBenefitTermDetail(benefitTermDetail as ITeamBenefitTermDetail)
            ),
        ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION,
        {
            toastErrorMessage: 'Failed to save benefit term details',
            toastSuccessMessage: 'Benefit term details saved',
        }
    );
