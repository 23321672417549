import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const RESET_ACCOUNT_LOCKOUT_ACTION = 'RESET_ACCOUNT_LOCKOUT';

export const resetAccountLockout = (userId: string | undefined, year: number) =>
    commonAction(
        async () => new UsersApi().resetAccountLockout(userId, year),
        RESET_ACCOUNT_LOCKOUT_ACTION,
        {
            toastErrorMessage: 'Unable to unlock account',
            toastSuccessMessage: 'Account successfully unlocked',
        }
    );
