import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';

export const GET_MONTH_AND_YEAR_OF_MOST_RECENT_PAYROLL_REPORT_ACTION =
    'GET_MONTH_AND_YEAR_OF_MOST_RECENT_PAYROLL_REPORT';

export const getMonthAndYearOfMostRecentPayrollReport = (teamId: string) =>
    commonAction(
        async () => new PayrollReportsApi().getMonthAndYearOfMostRecentPayrollReport(teamId),
        GET_MONTH_AND_YEAR_OF_MOST_RECENT_PAYROLL_REPORT_ACTION
    );
