import { renameRhFile } from 'actions/rhFile/renameRhFile';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const RENAME_USER_RH_FILES_ACTION = 'RENAME_USER_RH_FILES';
export const renameUserRhFile = (userId: string, rhFile: IRhFile) =>
    renameRhFile(
        RENAME_USER_RH_FILES_ACTION,
        async (newRhFile) => new RhFilesApi().renameUserRhFile(userId, newRhFile),
        rhFile
    );
