import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const DELETE_USER_MAILING_ADDRESS_ACTION = 'DELETE_USER_MAILING_ADDRESS';

export const deleteUserMailingAddress = (addressId: string, userId: string | undefined) =>
    commonAction(
        async () => new UsersApi().deleteAddress(userId, addressId),
        DELETE_USER_MAILING_ADDRESS_ACTION,
        {
            toastErrorMessage: 'Unable to remove mailing address',
        }
    );
