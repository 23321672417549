import commonAction from 'actions/commonAction';
import { INote, Note } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const ADD_USER_NOTE_ACTION = 'ADD_USER_NOTE';

export const addUserNote = (userId: string | undefined, noteText: string) =>
    commonAction(
        async () => new UsersApi().addNote(new Note({ noteText, userId } as INote)),
        ADD_USER_NOTE_ACTION
    );
