import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const SEND_RENEWAL_SELECTION_REVIEW_EMAIL = 'SEND_RENEWAL_SELECTION_REVIEW_EMAIL';

export const sendRenewalSelectionReviewEmail = (userId: string, year: number | undefined) =>
    commonAction(
        async () => new UsersApi().sendRenewalSelectionReviewEmail(userId, year),
        SEND_RENEWAL_SELECTION_REVIEW_EMAIL
    );
