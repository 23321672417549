import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';
import { Ordering } from 'api/generated/models';

export const LIST_SUBMITTED_EXPENSES_ACTION = 'LIST_SUBMITTED_EXPENSES';

export const listSubmittedExpenses = (
    teamId?: string,
    userId?: string,
    year?: number,
    page?: number,
    pageSize?: number,
    search?: string,
    includeArchived?: boolean,
    orderings?: Ordering[]
) =>
    commonAction(
        async () =>
            new SubmittedExpensesApi().listSubmittedExpenses(
                teamId,
                userId,
                year,
                includeArchived,
                page,
                pageSize,
                search,
                orderings
            ),
        LIST_SUBMITTED_EXPENSES_ACTION
    );
