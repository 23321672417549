import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const BULK_SET_SURVEY_TYPE_TO_SEND_ACTION = 'BULK_SET_SURVEY_TYPE_TO_SEND';

export const bulkSetSurveyTypeToSend = (teamId: string, SurveyTypeToSend: number) =>
    commonAction(
        async () => new TeamsApi().bulkSetSurveyTypeToSend(teamId, SurveyTypeToSend),
        BULK_SET_SURVEY_TYPE_TO_SEND_ACTION,
        {
            toastErrorMessage: 'Unable to update Survey Type To Send value for all team members.',
            toastSuccessMessage:
                'Successfully updated Survey Type To Send value for all team members.',
        }
    );
