import commonAction from 'actions/commonAction';
import {
    EditReimbursablePremiumAdjustmentRequest,
    IEditReimbursablePremiumAdjustmentRequest,
} from 'api/generated/models';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';

export const EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT_ACTION = 'EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT';

export const editReimbursablePremiumAdjustment = (
    reimbursablePremiumId: string | undefined,
    request: IEditReimbursablePremiumAdjustmentRequest
) =>
    commonAction(
        async () =>
            new PayrollReportsApi().editReimbursablePremiumAdjustment(
                reimbursablePremiumId,
                new EditReimbursablePremiumAdjustmentRequest(request)
            ),
        EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT_ACTION,
        {
            toastErrorMessage: 'Error updating Reimbursable Premium',
            toastSuccessMessage: 'Reimbursable Premium successfully updated',
        }
    );
