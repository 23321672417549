import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';
import { PlanDetailsAndRatesRequest } from 'api/generated/models';

export const GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION =
    'GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION';

export const getPlanDetailsForBenefitsSelection = (
    userId: string | undefined,
    planIds: string[],
    year: number,
    isOffExchange: boolean,
    isRefresh = true,
    overrideNeedsCoverage = false,
    actionType = GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION
) =>
    commonAction(
        async () =>
            new MarketplaceApi().getPlanDetailsForBenefitsSelection(
                userId,
                new PlanDetailsAndRatesRequest({
                    isOffExchange,
                    overrideNeedsCoverage,
                    planIds,
                    year,
                })
            ),
        actionType,
        {
            additionalDispatchData: {
                isRefresh,
            },
            toastErrorMessage: 'Unable to retrieve plan rates',
        }
    );
