import commonAction from 'actions/commonAction';
import { ILaunchDetails, LaunchDetails } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const UPDATE_LAUNCH_DETAILS_ACTION = 'UPDATE_LAUNCH_DETAILS';

export const updateLaunchDetails = (launchDetails: ILaunchDetails) =>
    commonAction(
        async () => new TeamsApi().updateLaunchDetails(new LaunchDetails(launchDetails)),
        UPDATE_LAUNCH_DETAILS_ACTION,
        {
            toastErrorMessage: (response) =>
                `Unable to update launch details. ${response.error.response?.data}`,
        }
    );
