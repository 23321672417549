import commonAction from 'actions/commonAction';
import CustomQuestionsApi from 'api/generated/CustomQuestionsApi';
import { CustomQuestion, ICustomQuestion } from 'api/generated/models';
import { hasValue } from 'utilities';

export const EDIT_CUSTOM_QUESTION_ACTION = 'EDIT_CUSTOM_QUESTION';

export const editCustomQuestion = (question: ICustomQuestion) =>
    commonAction(
        async () => new CustomQuestionsApi().editCustomQuestion(new CustomQuestion(question)),
        EDIT_CUSTOM_QUESTION_ACTION,
        {
            toastErrorMessage: (r) =>
                hasValue(r.error.response?.data)
                    ? r.error.response?.data
                    : 'Unable to edit question',
            toastSuccessMessage: 'Successfully edited question',
        }
    );
