import commonAction from 'actions/commonAction';
import RhFilesApi from 'api/generated/RhFilesApi';

export const TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED_ACTION = 'TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED';

export const toggleTeamBenefitRhFileShared = (
    teamBenefitId: string | undefined,
    rhFileId: string,
    isShared: boolean
) =>
    commonAction(
        async () => new RhFilesApi().toggleTeamBenefitRhFileShared(rhFileId, teamBenefitId),
        TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED_ACTION,
        {
            toastErrorMessage: `Failed to ${isShared ? 'un-share' : 'share'} file`,
            toastSuccessMessage: `Successfully ${isShared ? 'un-shared' : 'shared'} file`,
        }
    );
