import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION =
    'GET_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION';

export const getMemberReimbursementUtilizations = (userId: string, year: string) =>
    commonAction(
        async () =>
            new SubmittedExpensesApi().getMemberReimbursementUtilizations(userId, Number(year)),
        GET_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION
    );
