import commonAction from 'actions/commonAction';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const DELETE_TEAM_RH_FILE_ACTION = 'DELETE_TEAM_RH_FILE';

export const deleteTeamRhFile = ({ id }: IRhFile) =>
    commonAction(async () => new RhFilesApi().deleteTeamRhFile(id), DELETE_TEAM_RH_FILE_ACTION, {
        customErrorMessage: 'Failed to delete file',
        toastErrorMessage: 'Failed to delete file',
        toastSuccessMessage: 'File deleted',
    });
