import commonAction from 'actions/commonAction';
import { saveCompletedTeamTask } from 'actions/task/saveCompletedTeamTask';
import { TaskItems } from 'api/generated/enums';
import { TeamActionRequest } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const TRANSITION_TO_MEMBER_LAUNCH_ACTION = 'TRANSITION_TO_MEMBER_LAUNCH';

export const transitionToMemberLaunch = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().transitionTeamToMemberLaunch(new TeamActionRequest({ teamId })),
        TRANSITION_TO_MEMBER_LAUNCH_ACTION,
        {
            successHook: ({ dispatch }) => {
                dispatch(saveCompletedTeamTask(TaskItems.TransitionToMemberLaunch, teamId));
            },
            toastErrorMessage: 'Failed to transition to member launch',
            toastSuccessMessage: 'Successfully transitioned to member launch',
        }
    );
