import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import RhFilesApi from 'api/generated/RhFilesApi';

export const ADD_USER_PROFILE_PICTURE_ACTION = 'ADD_USER_PROFILE_PICTURE';

export const addUserProfilePicture = (
    userId: string | undefined,
    fileAndName: FileAndName[],
    isCurrent: boolean,
    toastSuccessMessage?: string,
    redirectUrl?: string
) =>
    addRhFiles(
        ADD_USER_PROFILE_PICTURE_ACTION,
        async (files, names) => new RhFilesApi().addUserProfilePicture(userId, names[0], files[0]),
        fileAndName,
        toastSuccessMessage,
        redirectUrl,
        {
            isCurrent,
        }
    );
