import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_TEAM_USER_ROLES_ACTION = 'GET_TEAM_USER_ROLES';

export const getTeamUserRoles = (teamId: string, ...roleIds: string[]) =>
    commonAction(
        async () => new UsersApi().listUsersByRole(teamId, roleIds),
        GET_TEAM_USER_ROLES_ACTION
    );
