import commonAction from 'actions/commonAction';
import RhFilesApi from 'api/generated/RhFilesApi';

export const DELETE_TEAM_BENEFIT_RH_FILE_ACTION = 'DELETE_TEAM_BENEFIT_RH_FILE';

export const deleteTeamBenefitRhFile = (teamBenefitId: string | undefined, fileId: string) =>
    commonAction(
        async () => new RhFilesApi().deleteTeamBenefitRhFile(teamBenefitId, fileId),
        DELETE_TEAM_BENEFIT_RH_FILE_ACTION,
        {
            toastErrorMessage: 'Failed to delete file',
            toastSuccessMessage: 'File deleted',
        }
    );
