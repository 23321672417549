import commonAction from 'actions/commonAction';
import { IResource, Resource } from 'api/generated/models';
import ResourcesApi from 'api/generated/ResourcesApi';

export const EDIT_RESOURCE_ACTION = 'EDIT_RESOURCE';

export const editResource = (resource: IResource) => {
    const r = new Resource(resource);
    return commonAction(async () => new ResourcesApi().editResource(r), EDIT_RESOURCE_ACTION, {
        toastErrorMessage: 'Unable to edit resource',
        toastSuccessMessage: 'Successfully edited resource',
    });
};
