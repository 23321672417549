import commonAction from 'actions/commonAction';
import { ForgotPassword } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const SEND_PASSWORD_RESET_EMAIL_ACTION = 'SEND_PASSWORD_RESET_EMAIL';

export const sendPasswordResetEmail = (email: string) =>
    commonAction(
        async () => new UsersApi().sendPasswordResetEmail(new ForgotPassword({ email })),
        SEND_PASSWORD_RESET_EMAIL_ACTION
    );
