import { addRhFiles, FileAndName } from 'actions/rhFile/addRhFiles';
import EnrollmentVerificationsApi from 'api/generated/EnrollmentVerificationsApi';

export const ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION = 'ADD_ENROLLMENT_VERIFICATION_RH_FILE';

export const addEnrollmentVerificationRhFile = (
    enrollmentVerificationId: string | undefined,
    fileAndNames: FileAndName[],
    toastSuccessMessage?: string,
    redirectUrl?: string
) =>
    addRhFiles(
        ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION,
        async (files, names) =>
            new EnrollmentVerificationsApi().addRhFiles(enrollmentVerificationId, files, names),
        fileAndNames,
        toastSuccessMessage,
        redirectUrl
    );
