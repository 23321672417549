import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';
import { MONTHS } from 'constants/date';
import HTTP_STATUS from 'constants/responseStatuses';

export const GENERATE_PAYROLL_REPORT_ACTION = 'GENERATE_PAYROLL_REPORT';

export const generatePayrollReport = (teamId: string, year: number, month: number) =>
    commonAction(
        async () => new PayrollReportsApi().generatePayrollReport(teamId, year, month),
        GENERATE_PAYROLL_REPORT_ACTION,
        {
            toastErrorMessage: (response) => {
                if (response.error.response?.status === HTTP_STATUS.CONFLICT) {
                    return `A payroll report already exists for ${MONTHS[month - 1]?.name} ${year}`;
                } else {
                    return `Unable to generate payroll report for ${
                        MONTHS[month - 1]?.name
                    } ${year}`;
                }
            },
            toastSuccessMessage: `Generated payroll report for ${MONTHS[month - 1]?.name} ${year}`,
        }
    );
