import commonAction from 'actions/commonAction';
import { getRoles } from 'actions/role/getRoles';
import { IRole, Role } from 'api/generated/models';
import RolesApi from 'api/generated/RolesApi';
import { push } from 'connected-react-router';
import { SYS_ROLES_PATH } from 'routers/routes';

export const ADD_ROLE_ACTION = 'ADD_ROLE';

export const addRole = (name: string) =>
    commonAction(async () => new RolesApi().addRole(new Role({ name } as IRole)), ADD_ROLE_ACTION, {
        successHook: ({ dispatch }) => {
            dispatch(push(SYS_ROLES_PATH));
            dispatch(getRoles());
        },
    });
