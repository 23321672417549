import commonAction from 'actions/commonAction';
import {
    ACCOUNT_IS_LOCKED_TEXT,
    LOCKED_OUT_ERROR_TEXT,
    TOKEN_ACTION,
} from 'actions/token/tokenActionUtility';
import { UserCredentials } from 'api/generated/models';
import TokenApi from 'api/generated/TokenApi';
import { TokenService } from 'security/TokenService';
import { hasValue } from 'utilities/index';

export const getToken = (email: string, password: string) =>
    commonAction(
        async () =>
            new TokenApi()
                .withRequestOptions({ withCredentials: true })
                .get(new UserCredentials({ email, password })),
        TOKEN_ACTION,
        {
            customErrorMessage: (response) => {
                const msg = (response.error.response?.data as unknown) as string;
                let errorText = 'Invalid email or password';
                if (msg === ACCOUNT_IS_LOCKED_TEXT) {
                    errorText = LOCKED_OUT_ERROR_TEXT;
                }
                return errorText;
            },
            successOverride: ({ response }) => {
                if (hasValue(response.data.intermediateToken)) {
                    TokenService.decodeAndCacheIntermediateToken(response.data.intermediateToken);
                } else {
                    TokenService.decodeAndCacheToken(
                        response.data.accessToken,
                        response.data.refreshToken
                    );
                }
            },
        }
    );
