import commonAction from 'actions/commonAction';
import IchraClassApi from 'api/generated/IchraClassApi';
import { IIchraClass } from 'api/generated/models';
import Toast from 'components/Toast';

export const APPLY_STRATEGY_ACTION = 'APPLY_STRATEGY';

export const applyStrategy = (
    ichraClass: IIchraClass,
    setNeedsApplyStrategy: React.Dispatch<React.SetStateAction<boolean>>
) =>
    commonAction(
        async () =>
            new IchraClassApi().applyStrategy(
                ichraClass.globalId,
                ichraClass.pathwayBlueprintId,
                ichraClass.topLineReimbursementStrategyId,
                ichraClass.benchmarkPlanType,
                ichraClass.zipCode,
                ichraClass.county,
                ichraClass.state,
                ichraClass.benchmarkCarrier,
                ichraClass.benchmarkPlanId,
                ichraClass.dependentKickerCoveragePercentage,
                Number(ichraClass.eeCoveragePercentage),
                ichraClass.esCoveragePercentage,
                ichraClass.ecCoveragePercentage,
                ichraClass.efCoveragePercentage,
                ichraClass.otherHouseholdMemberCoveragePercentage
            ),
        APPLY_STRATEGY_ACTION,
        {
            nonSuccessHook: ({ response }) => {
                Toast.error(
                    `Unable to apply strategy for ICHRA Class. ${response.error.response?.data}`
                );
                setNeedsApplyStrategy(true);
            },
            successHook: () => {
                Toast.success('Successfully applied strategy for ICHRA Class.');
                setNeedsApplyStrategy(false);
            },
        }
    );
