import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_CUSTOM_QUESTION_ANSWERS_ACTION = 'GET_CUSTOM_QUESTION_ANSWERS';

export const getCustomQuestionAnswers = (userId: string) =>
    commonAction(
        async () => new UsersApi().getCustomQuestionAnswers(userId),
        GET_CUSTOM_QUESTION_ANSWERS_ACTION
    );
