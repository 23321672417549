import commonAction from 'actions/commonAction';
import { getUserProfile } from 'actions/user/getUserProfile';
import { IMemberVerifiedInfo } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_MEMBER_VERIFIED_INFO_ACTION = 'PATCH_MEMBER_VERIFIED_INFO';

export const patchMemberVerifiedInfo = (
    memberVerifiedInfoId: string | undefined,
    userId: string | undefined,
    year: number,
    memberVerifiedInfo: Partial<IMemberVerifiedInfo>,
    isCurrent: boolean
) => {
    const memberVerifiedInfoPatch = objectToPatchOperationsArray(memberVerifiedInfo);
    return commonAction(
        async () =>
            new UsersApi().patchMemberVerifiedInfo(memberVerifiedInfoId, memberVerifiedInfoPatch),
        PATCH_MEMBER_VERIFIED_INFO_ACTION,
        {
            additionalDispatchData: { isCurrent },
            nonSuccessHook: ({ dispatch }) => dispatch(getUserProfile(userId, isCurrent, year)),
            toastErrorMessage: 'Unable to update the user',
        }
    );
};
