import commonAction from 'actions/commonAction';
import StatsApi from 'api/generated/StatsApi';

export const GET_TEAM_MEMBERS_STATS_ACTION = 'GET_TEAM_MEMBERS_STATS';

export const getTeamMembersStats = (teamId: string, year: number | undefined) =>
    commonAction(
        async () => new StatsApi().getTeamMembers(teamId, year),
        GET_TEAM_MEMBERS_STATS_ACTION
    );
