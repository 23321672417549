import commonAction from 'actions/commonAction';
import SelectedPlansApi from 'api/generated/SelectedPlansApi';

export const GET_SELECTED_PLANS_FOR_REVIEW_ACTION = 'GET_SELECTED_PLANS_FOR_REVIEW';

export const getSelectedPlansForReview = (userId: string | undefined) =>
    commonAction(
        async () => new SelectedPlansApi().getPlansForReview(userId),
        GET_SELECTED_PLANS_FOR_REVIEW_ACTION
    );
