import commonAction from 'actions/commonAction';
import SelectedPlansApi from 'api/generated/SelectedPlansApi';

export const GET_SELECTED_PLANS_FOR_RENEWAL_ACTION = 'GET_SELECTED_PLANS_FOR_RENEWAL';

export const getSelectedPlansForRenewal = (userId: string, year: number) =>
    commonAction(
        async () => new SelectedPlansApi().getPlansForRenewal(userId, year),
        GET_SELECTED_PLANS_FOR_RENEWAL_ACTION
    );
