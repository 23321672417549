import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const SEND_BENEFITS_SELECTED_EMAILS_ACTION = 'SEND_BENEFITS_SELECTED_EMAILS';

export const sendBenefitsSelectedEmails = (userId: string) =>
    commonAction(
        async () => new UsersApi().sendBenefitsSelectedEmails(userId),
        SEND_BENEFITS_SELECTED_EMAILS_ACTION
    );
