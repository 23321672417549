import commonAction from 'actions/commonAction';
import { IPayrollReportUserSnapshot } from 'api/generated/models';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION = 'PATCH_PAYROLL_REPORT_USER_SNAPSHOT';

export const patchPayrollReportUserSnapshot = (
    payrollReportUserSnapshotId: string | undefined,
    patch: Partial<IPayrollReportUserSnapshot>,
    toastMessageSubject: string
) =>
    commonAction(
        async () =>
            new PayrollReportsApi().patchPayrollReportUserSnapshot(
                payrollReportUserSnapshotId,
                objectToPatchOperationsArray(patch)
            ),
        PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION,
        {
            toastErrorMessage: `Error updating ${toastMessageSubject}`,
            toastSuccessMessage: `${toastMessageSubject} successfully updated`,
        }
    );
