import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const GET_YEARLY_REIMBURSEMENT_REPORT_ACTION = 'GET_YEARLY_REIMBURSEMENT_REPORT';

export const getYearlyReimbursementReport = (teamId: string, year: number) =>
    commonAction(
        async () => new SubmittedExpensesApi().getYearlyReimbursementReport(teamId, year),
        GET_YEARLY_REIMBURSEMENT_REPORT_ACTION
    );
