import commonAction from 'actions/commonAction';
import TasksApi from 'api/generated/TasksApi';

export const GET_COMPLETED_TEAM_TASKS_ACTION = 'GET_COMPLETED_TEAM_TASKS';

export const getCompletedTeamTasks = (teamId: string) =>
    commonAction(
        async () => new TasksApi().listCompletedTeamTasks(teamId),
        GET_COMPLETED_TEAM_TASKS_ACTION
    );
