import  commonAction from 'actions/commonAction';
import { IPathwayUser } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_PATHWAY_USER_ACTION = 'PATCH_PATHWAY_USER';

export const patchPathwayUser = (
    pathwayUserId: string,
    patch: Partial<IPathwayUser>,
    toastMessageSubject: string
) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().patchPathwayUser(
                pathwayUserId,
                objectToPatchOperationsArray(patch, true, true)
            ),
        PATCH_PATHWAY_USER_ACTION,
        {
            toastErrorMessage: `Error updating ${toastMessageSubject}`,
            toastSuccessMessage: `${toastMessageSubject} successfully updated`,
        }
    );
