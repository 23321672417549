import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const TRANSITION_TO_RH_LAUNCH_ACTION = 'TRANSITION_TO_RH_LAUNCH';

export const transitionToRhLaunch = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().transitionTeamToRhLaunchFromProspect(teamId),
        TRANSITION_TO_RH_LAUNCH_ACTION,
        {
            toastErrorMessage: 'Unable to set team to RH Launch status',
            toastSuccessMessage: 'Successfully set team to RH Launch status.',
        }
    );
