import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const GET_TEAM_RELATIONSHIPS_ACTION = 'GET_TEAM_RELATIONSHIP';

export const getTeamRelationships = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().getTeamRelationships(teamId),
        GET_TEAM_RELATIONSHIPS_ACTION
    );
