import commonAction from 'actions/commonAction';
import { TeamStateIds } from 'api/generated/enums';
import { Address, IAddress, ITeam, Team, TeamProfile } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { TEAMS_DASHBOARD_PATH } from 'routers/routes';

export const ADD_TEAM_ACTION = 'ADD_TEAM';

export const addTeam = (
    name: string,
    isCustomer: boolean | undefined,
    isPartner: boolean,
    isAdvisor: boolean | undefined,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    county: string,
    countyFips: string,
    email: string,
    teamStateId: TeamStateIds | undefined
) => {
    const teamProfile = new TeamProfile({
        address: new Address({
            addressLine1,
            addressLine2,
            city,
            county,
            countyFips,
            state,
            zip,
        } as IAddress),
        adminEmail: undefined,
        team: new Team({
            isAdvisor,
            isCustomer,
            isPartner,
            name,
            teamStateId,
        } as ITeam),
    });
    if (email) {
        teamProfile.adminEmail = email;
    }
    return commonAction(async () => new TeamsApi().add(teamProfile), ADD_TEAM_ACTION, {
        successHook: ({ dispatch, response }) => {
            const { teamId } = response.data.team ?? {};
            const teamPath = generatePath(TEAMS_DASHBOARD_PATH, { teamId });
            dispatch(push(teamPath));
        },
        toastErrorMessage: 'Unable to add team',
        toastSuccessMessage: 'Team has been created',
    });
};
