/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
export enum OtherCoverageEligibilities {
    None = 0,
    SpouseEmployer = 1,
    Parent = 2,
    DependentEmployer = 4,
}
export enum ProviderSource {
    User = 0,
    Cms = 1,
    Ideon = 2,
}
export enum TaxFilingStatuses {
    Single = 0,
    MarriedFilingJointly = 1,
    MarriedFilingSeparately = 2,
    HeadOfHousehold = 3,
    QualifyingWidowWithDependentChild = 4,
    Unknown = 100,
}
export enum SurveyTypes {
    Standard = 0,
    ICHRA = 1,
}
export enum AddressTypes {
    Home = 0,
    Mailing = 1,
}
export enum HouseholdMemberTypes {
    Spouse = 0,
    Dependent = 1,
    Unclaimed = 2,
    IchraChild = 3,
}
export enum PlanStateIds {
    Selected = 0,
    Submitted = 1,
    Enrolled = 2,
    Effective = 3,
    Terminated = 4,
    ReadyForAutoEnrollment = 5,
}
export enum PlanTypeIds {
    Marketplace = 0,
    MediShare = 1,
    TermMedical = 2,
    Medicare = 3,
    Medicaid = 4,
    CHIP = 5,
    VeteransAffairs = 6,
    SpouseEmployer = 7,
    ParentEmployer = 8,
    Ancillary = 9,
    CustomStateBasedExchange = 10,
    CustomOffExchange = 11,
    CustomMajorMedical = 12,
    OffExchange = 13,
    CustomAncillary = 14,
}
export enum HealthCareTypeIds {
    Medical = 0,
    Dental = 1,
    Vision = 2,
    HealthCareSharing = 3,
    Accident = 4,
    Cancer = 5,
    CriticalIllness = 6,
    Disability = 7,
    Foundation = 8,
    GAP = 9,
    HospitalIndemnity = 10,
    LifeMembership = 11,
    MEC = 12,
    MedicalBridge = 13,
    PlanEnhancer = 14,
    ShareFee = 15,
    TermLife = 16,
    TrioMed = 17,
    WholeLife = 18,
}
export enum BinderStatuses {
    NeedsPaid = 0,
    Paid = 1,
    NotPaying = 2,
    Auto = 3,
    PendingSepMedicaid = 4,
    PaymentPending = 5,
    Issue = 6,
}
export enum PayTypeIds {
    RhPaid = 0,
    MemberPaid = 1,
}
export enum MedicalReimbursementTypes {
    Embedded = 0,
    NonEmbedded = 1,
}
export enum GroupLevels {
    EE = 0,
    ES = 1,
    EC = 2,
    EF = 3,
    W = 4,
    UK = 100,
}
export enum ReimbursementDisplayLevels {
    Individual = 0,
    IndividualAndFamily = 1,
    Family = 2,
    None = 100,
}
export enum TeamBenefitTypes {
    Telehealth = 1,
    GroupVision = 2,
    GroupDental = 3,
    LifeInsurance = 4,
    LongTermDisability = 5,
    ShortTermDisability = 6,
    CriticalIllness = 7,
    ComplianceMinimumEssentialCoverage = 8,
    PreventativeMinimumEssentialCoverage = 9,
    HealthReimbursementArrangement = 10,
    HealthSavingsAccount = 11,
    FlexibleSavingsAccount = 12,
    ReimbursementProgram = 13,
    DirectPrimaryCare = 14,
}
export enum AutoEnrollmentStatus {
    Ready = 0,
    Success = 1,
    Failure = 2,
}
export enum UserTeamBenefitTermDetailStatuses {
    Enrolled = 0,
    Submitted = 1,
    Waived = 2,
    Offered = 3,
    Unknown = 100,
}
export enum ContributionFrequency {
    Monthly = 0,
    Yearly = 1,
}
export enum ExpenseInputTypes {
    IndividualMedicalResponsibility = 0,
    FamilyMedicalResponsibility = 1,
    IndividualMedicalReimbursementAmount = 2,
    FamilyMedicalReimbursementAmount = 3,
    MedicalReimbursementType = 4,
    PerVisitReimbursementAmount = 5,
    MaximumVisitsPerYear = 6,
    AnnualReimbursementAmount = 7,
}
export enum MajorMedicalWaitingPeriodIds {
    Immediate = 0,
    ThirtyDays = 1,
    SixtyDays = 2,
    NinetyDays = 3,
    FirstDayOfNextMonth = 4,
    OffsetThenFirstDayOfNextMonth = 5,
}
export enum TerminationDateDelays {
    Immediate = 0,
    LastDayOfMonth = 1,
}
export enum HrsIntegrationProviders {
    NotConfigured = 0,
    Adp = 1,
    Paylocity = 2,
    Finch = 3,
}
export enum AdpIntegrationStatuses {
    NotConfigured = 0,
    Disconnected = 1,
    ConsentRequired = 2,
    Connected = 3,
}
export enum PaylocityIntegrationStatuses {
    NotConfigured = 0,
    Disconnected = 1,
    ConsentRequired = 2,
    Connected = 3,
}
export enum FinchIntegrationStatuses {
    NotConfigured = 0,
    Disconnected = 1,
    ConsentRequired = 2,
    Connected = 3,
    Reauth = 4,
}
export enum DeductionStatus {
    NotConfigured = 0,
    Processing = 1,
    Valid = 2,
    Error = 3,
}
export enum TeamStateIds {
    Prospect = 1,
    Proposal = 2,
    Inactive = 6,
    RhLaunch = 7,
    TeamAdminLaunch = 8,
    MemberLaunch = 9,
    ReadyForMemberLaunch = 10,
    Lead = 13,
    Analysis = 14,
    Handoff = 15,
    Customer = 16,
    Offboarding = 17,
    Renewing = 18,
}
export enum ShoppingConfigurationIds {
    Marketplace = 1,
    MediShare = 2,
    OffExchange = 4,
}
export enum PartnerTeams {
    Brotherhood = 0,
    ChristianCareMinistry = 1,
    Careington = 2,
    Colonial = 3,
    CareSource = 4,
    Ambetter = 5,
    TeamNational = 6,
    ServantSolution = 7,
}
export enum ReimbursementReportFrequencies {
    Monthly = 0,
    SemiMonthly = 1,
}
export enum FinchEmploymentSubtypes {
    FullTime = 1,
    Intern = 2,
    PartTime = 4,
    Temp = 8,
    Seasonal = 16,
}
export enum CategoryIds {
    General = 1,
    Marketing = 2,
    Default = 3,
}
export enum UserStatus {
    Active = 0,
    Launching = 1,
    Renewing = 2,
    Inactive = 3,
    OffBoarding = 4,
    Waived = 5,
}
export enum LineFourteenCodes {
    OneF = 0,
    OneL = 1,
    OneM = 2,
    OneN = 3,
    OneR = 4,
}
export enum LineSixteenCodes {
    TwoC = 0,
    TwoF = 1,
}
export enum PayrollReportChangeDiffType {
    Added = 0,
    Changed = 1,
    Removed = 2,
}
export enum CustomExpenseTypes {
    Fee = 0,
    Premium = 1,
    Other = 2,
}
export enum MonthlyFeeTypes {
    Flat = 0,
    PEPM = 1,
}
export enum IchraOfferLevels {
    Employee = 0,
    EmployeeChildren = 1,
    EmployeeSpouseChildren = 2,
}
export enum RhManagementTypes {
    RHManagesAndPays = 0,
    AgentOfRecord = 1,
    RHManagesButDoesNotPay = 2,
    RHDoesNotManageButDoesPay = 3,
}
export enum OfferLevels {
    EmployeeOnly = 0,
    EmployeeSpouse = 1,
    EmployeeSpouseChildren = 2,
}
export enum EnrollmentVerificationTypes {
    LossOfHealthCoverage = 0,
    AmericanIndianOrAlaskanNativeStatus = 1,
    CitizenshipVerification = 2,
    JobBasedCoverageStatus = 3,
    ImmigrationStatus = 4,
    IncarcerationStatus = 5,
    IncomeVerification = 6,
    MarriageStatus = 7,
    MedicaidOrChipStatus = 8,
    MedicareCoverageStatus = 9,
    SsnVerification = 10,
    VaCoverageStatus = 11,
    TricareCoverageStatus = 12,
    PeaceCorpsCoverageStatus = 13,
    CostToJoinOtherCoverage = 14,
}
export enum EnrollmentVerificationStatuses {
    Needed = 0,
    Received = 1,
    Submitted = 2,
    Verified = 3,
}
export enum ExpenseStatus {
    Submitted = 0,
    Approved = 1,
    Denied = 2,
    InformationNeeded = 3,
    Resubmitted = 4,
}
export enum Flows {
    IchraLaunch = 0,
    IchraRenewal = 1,
    SignUpdatedMemberTerms = 2,
}
export enum Tasks {
    WelcomeToRemodelHealth = 0,
    EnterHouseholdInfo = 2,
    YourReimbursementAmount = 3,
    SelectBenefits = 4,
    PersonalInfo = 5,
    HouseholdMembers = 6,
    PrimaryAddressInfo = 7,
    YourJob = 8,
    SocialSecurityNumbers = 9,
    ChooseYourPlan = 10,
    RecommendedBenefitsPath = 11,
    ShopForPlans = 12,
    PreferredProviders = 13,
    Prescriptions = 14,
    CurrentHealthConditions = 15,
    MemberTerms = 16,
    HouseholdSummary = 17,
    Advisement = 18,
    SelectAncillaryBenefits = 19,
    SubmitBenefitSelections = 20,
    KeepOrChange = 21,
    ChangeAdvisement = 22,
    MedicareOnly = 23,
}
export enum TaskStatuses {
    Incomplete = 0,
    Skipped = 1,
    Completed = 2,
    Acknowledged = 3,
    Overridden = 4,
}
export enum PathwayTypes {
    MarketplaceCappedEmployeeCost = 0,
    HealthcareSharingCappedEmployeeCost = 1,
    Custom = 2,
    Spouse = 3,
    Medicaid = 4,
    Medicare = 5,
    MarketplaceCappedEmployerCost = 6,
    Flat = 7,
    Waived = 8,
    HealthcareSharingCappedEmployerCost = 9,
    Parent = 10,
    IchraCappedEmployeeCost = 11,
    IchraCappedEmployerCost = 12,
    IchraMedicare = 13,
    IchraFlat = 14,
    IchraCustom = 15,
    IchraAgeBanded = 16,
}
export enum PathwayInputTypes {
    CapType = 0,
    CoverageLevelEE = 1,
    CoverageLevelES = 2,
    CoverageLevelEC = 3,
    CoverageLevelEF = 4,
    WageUpCap = 6,
    Zipcode = 7,
    County = 8,
    MarketplaceCarrier = 9,
    MarketplacePlanId = 10,
    ChipEstimate = 11,
    PerMemberMedicareCostEstimate = 12,
    MediShareBenchmarkProgram = 13,
    StartingWageUp = 14,
    AutomaticApprovalCap = 15,
    WaivedContribution = 16,
    MedicaidEstimate = 17,
    ExtraBenefitText = 18,
    DisplayNameOverride = 19,
    IchraBenchmarkType = 20,
    EmployerCoversEeOnly = 21,
    UseRestrategizedRenewalFlow = 22,
    AgeBandSize = 23,
    SixtyFourYearOldEE = 24,
    SixtyFourYearOldES = 25,
    SixtyFourYearOldEC = 26,
    SixtyFourYearOldEF = 27,
    AdditionalDependentKicker = 28,
    EeReimbursementRatio = 29,
    EsReimbursementRatio = 30,
    EcReimbursementRatio = 31,
    EfReimbursementRatio = 32,
    DependentKickerThreshold = 33,
}
export enum TopLineReimbursementStrategies {
    Custom = 0,
    CoverageLevelPercentage = 1,
    SplitPrimaryHouseholdPercentage = 2,
    Import = 3,
}
export enum AgeBandSizes {
    One = 0,
    Five = 1,
    Ten = 2,
    Custom = 3,
}
export enum PlanListing {
    OnExchange = 0,
    OffExchange = 1,
}
export enum PathwayUserTags {
    None = 0,
    Medicaid = 1,
    CHIP = 2,
    Medicare = 4,
    MedicareHousehold = 8,
    TaxCredits = 16,
    UnsupportedStateBasedExchangeIneligible = 32,
    CSR = 64,
    SpouseEmployer = 128,
    Parent = 256,
    LostCHIP = 512,
    LostCSR = 1024,
    LostTaxCredits = 2048,
    MarriedFilingSeparately = 4096,
    Ineligible = 8192,
    Unclaimed = 16384,
    MedicaidGap = 32768,
    LeftMedicaidGap = 65536,
    WaivingTaxCredits = 131072,
    DependentOver25 = 262144,
    WageUpFloor = 524288,
    LostMedicaid = 1048576,
    UnsupportedOffExchange = 2097152,
    TobaccoUse = 4194304,
    IchraUnaffordable = 8388608,
    AffordabilityUnknown = 16777216,
    IneligiblePlans = 33554432,
}
export enum UserInfoStatus {
    Basic = 0,
    MemberQuoteInfo = 1,
    MemberVerifiedInfo = 2,
}
export enum UserRelationshipTypeIds {
    Advisor = 0,
}
export enum YearlyUserInfoTypes {
    EnrollmentStatus = 0,
    AllowShopping = 1,
    AcceptedPathway = 2,
    AdvisementCompleted = 3,
    RenewalDecision = 4,
    IsMinister = 5,
    ApplicationSubmittedBy = 6,
    UsePremiumTaxCredits = 7,
    NeedsMajorMedicalCoverage = 8,
    OtherCoverageSource = 9,
    MajorMedicalCoverageLevel = 10,
    HsaContributionLimitOverride = 11,
    AllowRecurringExpenses = 12,
    FinalReviewCompleted = 13,
    AdvisementType = 14,
}
export enum EnrollmentStatuses {
    ReadyForLaunch = 0,
    InvitedToLaunch = 1,
    AwaitingTaskList = 2,
    BenefitsSelection = 3,
    PendingApplication = 4,
    Enrolled = 5,
    PendingWageUpRequest = 6,
    AdvisementScheduled = 7,
    AdvisementNeeded = 8,
    PendingDecision = 9,
    Waived = 10,
    ReadyForRenewal = 11,
    InvitedToRenewal = 12,
}
export enum RenewalDecisions {
    Keep = 0,
    Change = 1,
    PassiveRenewal = 2,
    AcceptedNewPathway = 3,
    ChoseNonPathwayPlan = 4,
}
export enum AdvisementTypes {
    NewGroup = 0,
    NewAdd = 1,
    LifeChange = 2,
    Renewal = 3,
}
export enum SortOrder {
    Ascending = 0,
    Descending = 1,
    Unspecified = -1,
}
export enum HouseholdEligibilityStatus {
    None = 0,
    Medicaid = 1,
    CHIP = 2,
    Medicare = 4,
    MedicareHousehold = 8,
    RH = 16,
    Other = 32,
    TaxCredits = 64,
    UnsupportedStateBasedExchangeIneligible = 128,
    CSR = 256,
    MedicaidGap = 512,
    HouseholdCoveredByMedicareOrChip = 1024,
}
export enum EntityType {
    User = 1,
    HouseholdMember = 2,
}
export enum HealthInsuranceParts {
    LowDeductible = 1,
    Copays = 2,
    LowMaxOutOfPocket = 3,
    MonthlyCostForThePlan = 4,
    MyDoctorsInNetwork = 5,
    NameBrandCarrier = 6,
}
export enum OtherBenefits {
    Dental = 1,
    Vision = 2,
    FlexibleSpendingAccount = 3,
    HealthSavingsAccount = 4,
    HealthReimbursementAccount = 5,
    Cancer = 6,
    CriticalIllness = 7,
    Hospital = 8,
    Life = 9,
    AccidentalDeathAndDismemberment = 10,
    LongTermDisability = 11,
    Offers401k403b = 12,
    PetInsurance = 13,
}
export enum ChangeTypes {
    Good = 1,
    Neutral = 2,
    Bad = 3,
}
export enum SelectedPlanComparisonTypes {
    Unknown = 0,
    PlanCost = 1,
    Deductible = 2,
    MaxOutOfPocket = 3,
    Benefit = 4,
    Household = 5,
}
export enum IneligibleToKeepReasons {
    IneligibleForPlan = 0,
    PlanDiscontinued = 1,
    HouseholdEligibilityChanges = 2,
    IncludesUnclaimedMembers = 3,
}
export enum PathwayDecisions {
    Accepted = 1,
    Skip = 2,
    WageUpRequested = 3,
}
export enum Products {
    Company = 0,
    Directory = 1,
    Individual = 2,
    Employment = 3,
    Payment = 4,
    PayStatement = 5,
    Benefits = 6,
}
export enum AssignableReportOptions {
    Current = 0,
    Next = 1,
    Previous = -1,
}
export enum HsaEligibility {
    Ineligible = 0,
    Individual = 1,
    Family = 2,
}
export enum MedicaidApplicationStatuses {
    Accepted = 0,
    AcceptedPendingLetter = 1,
    Pending = 2,
    DeniedPendingLetter = 3,
    Denied = 4,
    Unresponsive = 5,
}
export enum FamilyMemberTypes {
    Adults = 0,
    Children = 1,
    AdultsAndChildren = 2,
}
export enum MedicaidRenewalStatuses {
    Renewed = 0,
    NotStarted = 1,
    Pending = 2,
    Denied = 3,
}
export enum SourceTypes {
    PreTaxPlan = 0,
    TeamBenefit = 1,
}
export enum SearchResultType {
    User = 0,
    Team = 1,
    HouseholdMember = 2,
}
export enum TeamStrategies {
    Payroll = 1,
    ReimbursementProgram = 2,
    MemberLaunchFlow = 3,
    NeedsCoverageTesting = 4,
}
export enum TaskItems {
    CompleteTeamProfile = 1,
    GoToTeamMembersPage = 2,
    AddTeamMembers = 3,
    CompleteMemberInfo = 4,
    ScheduleTeamAdminSetupCall = 5,
    CompleteLaunchDetails = 6,
    SetRhLaunchComplete = 7,
    ReviewLaunchTimeline = 8,
    VerifyTeamInfo = 9,
    VerifyTeamRoster = 10,
    ReviewTeamForMemberLaunch = 11,
    TransitionToMemberLaunch = 12,
    ReviewMemberInfo = 13,
    ReviewKickoffDetails = 14,
    ReviewBenefitsInfo = 15,
    AcceptRHServices = 16,
    EnterSocialSecurityNumbers = 17,
    AcceptRHServices_Renewing = 18,
    ReviewMemberInfo_Renewing = 19,
    KeepOrChangeBenefits_Renewing = 20,
    EnterSocialSecurityNumbers_Renewing = 21,
    BenefitSelection = 22,
    BenefitSelection_Renewing = 23,
    AncillaryBenefitsSelection = 24,
    AncillaryBenefitsSelection_Renewing = 25,
}
export enum TaskGroups {
    TeamAdminProspect = 1,
    UserSetup = 2,
    RhLaunchTasks = 3,
    TeamAdminLaunch = 4,
    RhTeamMemberReadyForMemberLaunchTasks = 5,
    MemberMemberLaunchTasks = 6,
    MemberRenewingTasks = 7,
}
export enum RelationshipTypeIds {
    EngagementRepresentative = 0,
    LaunchRepresentative = 1,
    CustomerSuccessRepresentative = 2,
    AccountManager = 3,
}
