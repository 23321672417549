import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const GET_PATHWAY_USER_ACTION = 'GET_PATHWAY_USER';

export const getPathwayUser = (pathwayUserId: string | undefined) =>
    commonAction(
        async () => new PathwayBlueprintApi().getPathwayUser(pathwayUserId),
        GET_PATHWAY_USER_ACTION
    );
