import commonAction from 'actions/commonAction';
import { AddressTypes } from 'api/generated/enums';
import UsersApi from 'api/generated/UsersApi';

export const DELETE_HOUSEHOLD_MEMBER_ADDRESS_ACTION = 'DELETE_HOUSEHOLD_MEMBER_ADDRESS';

export const deleteHouseholdMemberAddress = (
    addressId: string,
    householdMemberId: string,
    addressType: AddressTypes
) =>
    commonAction(
        async () => new UsersApi().deleteHouseholdMemberAddress(householdMemberId, addressId),
        DELETE_HOUSEHOLD_MEMBER_ADDRESS_ACTION,
        {
            additionalDispatchData: { addressType, householdMemberId },
            toastErrorMessage: "Unable to remove the household member's address",
        }
    );
