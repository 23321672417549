import commonAction from 'actions/commonAction';
import SelectedPlansApi from 'api/generated/SelectedPlansApi';

export const DELETE_SELECTED_PLAN_ACTION = 'DELETE_SELECTED_PLAN';

export const deleteSelectedPlan = (
    selectedPlanId: string | undefined,
    toastSuccessMessage?: string
) =>
    commonAction(
        async () => new SelectedPlansApi().delete(selectedPlanId),
        DELETE_SELECTED_PLAN_ACTION,
        {
            toastSuccessMessage,
        }
    );
