import commonAction from 'actions/commonAction';
import FinchApi from 'api/generated/FinchApi';
import { CreateConnectSessionCommand } from 'api/generated/models';

export const CREATE_FINCH_CONNECT_SESSION_ACTION = 'CREATE_FINCH_CONNECT_SESSION';

export const createFinchConnectSession = (teamId: string) =>
    commonAction(
        async () =>
            new FinchApi().createConnectSession(new CreateConnectSessionCommand({ teamId })),
        CREATE_FINCH_CONNECT_SESSION_ACTION
    );
