import commonAction from 'actions/commonAction';
import { IUserTeamBenefitTermDetail } from 'api/generated/models';
import TeamBenefitsApi from 'api/generated/TeamBenefitsApi';
import { objectToPatchOperationsArray } from 'api/utilities';
import Toast from 'components/Toast';
import { hasValue } from 'utilities';

export const PATCH_USER_TEAM_BENEFIT_TERM_DETAIL_ACTION =
    'PATCH_USER_TEAM_BENEFIT_TERM_DETAIL_ACTION';

export const addOrPatchUserTeamBenefitTermDetail = (
    teamBenefitTermDetailId: string | undefined,
    userId: string | undefined,
    userTeamBenefitTermDetail: Partial<IUserTeamBenefitTermDetail>
) => {
    const userTeamBenefitPatch = objectToPatchOperationsArray(userTeamBenefitTermDetail);
    return commonAction(
        async () =>
            new TeamBenefitsApi().addOrPatchUserTeamBenefitTermDetail(
                teamBenefitTermDetailId,
                userId,
                userTeamBenefitPatch
            ),
        PATCH_USER_TEAM_BENEFIT_TERM_DETAIL_ACTION,
        {
            additionalDispatchData: {
                params: { userId },
            },
            nonSuccessHook: ({ response }) => {
                const message = (response.error.response?.data as unknown) as string;
                if (hasValue(message)) {
                    Toast.error(message);
                    throw new Error(message);
                }
            },
        }
    );
};
