import commonAction from 'actions/commonAction';
import { ITeamPlan, TeamPlan } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';

export const EDIT_TEAM_PLAN_ACTION = 'EDIT_TEAM_PLAN';

export const editTeamPlan = (teamPlan: ITeamPlan) =>
    commonAction(
        async () => new TeamsApi().editTeamPlan(new TeamPlan(teamPlan)),
        EDIT_TEAM_PLAN_ACTION
    );
