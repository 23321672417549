import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const SEARCH_PROVIDERS_ACTION = 'SEARCH_PROVIDERS';

export const searchProviders = (zipCode: string, query: string, offset: number) =>
    commonAction(
        async () => new MarketplaceApi().searchProviders(zipCode, query, offset),
        SEARCH_PROVIDERS_ACTION
    );
