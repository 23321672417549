import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_QUALITATIVE_ANSWERS_ACTION = 'GET_QUALITATIVE_ANSWERS';

export const getQualitativeAnswers = (userId: string) =>
    commonAction(
        async () => new UsersApi().getQualitativeAnswers(userId),
        GET_QUALITATIVE_ANSWERS_ACTION
    );
