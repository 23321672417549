import commonAction from 'actions/commonAction';
import { getRoles } from 'actions/role/getRoles';
import { IRole, Role } from 'api/generated/models';
import RolesApi from 'api/generated/RolesApi';
import { push } from 'connected-react-router';
import { SYS_ROLES_PATH } from 'routers/routes';

export const EDIT_ROLE_ACTION = 'EDIT_ROLE';

export const editRole = (roleId: string, name: string) =>
    commonAction(
        async () => new RolesApi().editRole(new Role({ name, roleId } as IRole)),
        EDIT_ROLE_ACTION,
        {
            successHook: ({ dispatch }) => {
                dispatch(push(SYS_ROLES_PATH));
                dispatch(getRoles());
            },
        }
    );
