import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const ADD_EXPENSE_TYPE_ACTION = 'ADD_EXPENSE_TYPE_ACTION';

export const addExpenseType = (expenseTypeName: string) =>
    commonAction(
        async () => new SubmittedExpensesApi().addExpenseType(expenseTypeName),
        ADD_EXPENSE_TYPE_ACTION,
        {
            toastErrorMessage: 'Failed to add Expense Type',
            toastSuccessMessage: 'Successfully added Expense Type',
        }
    );
