import commonAction from 'actions/commonAction';
import { ITeamBenefit, TeamBenefit } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
export const EDIT_TEAM_BENEFIT_ACTION = 'EDIT_TEAM_BENEFIT';

export const editTeamBenefit = (newTeamBenefit: ITeamBenefit) =>
    commonAction(
        async () => new TeamsApi().editTeamBenefit(new TeamBenefit(newTeamBenefit)),
        EDIT_TEAM_BENEFIT_ACTION
    );
