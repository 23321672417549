import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';
import { API_DATE_FORMAT, DISPLAY_DATE_FORMAT } from 'utilities/moment';

export const GET_INFO_TO_SUBMIT_EXPENSE_ACTION = 'GET_INFO_TO_SUBMIT_EXPENSE';

export const getUserInfoToSubmitExpense = (userId: string, date: string) => {
    const apiDate = date.toMomentDate(DISPLAY_DATE_FORMAT);
    return commonAction(
        async () =>
            new SubmittedExpensesApi().getUserInfoToSubmitExpense(
                userId,
                apiDate.format(API_DATE_FORMAT)
            ),
        GET_INFO_TO_SUBMIT_EXPENSE_ACTION
    );
};
