import { dispatchWrapper } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { IUserTermDetailManageMemberDto } from 'api/generated/models';
import { TableState } from 'react-table';
import {
    transformFiltersToEnumArray,
    transformSortByToOrderingArray,
} from 'utilities/teamBenefits';

export const SET_MANAGE_MEMBERS_SEARCH = 'SET_MANAGE_MEMBERS_SEARCH';
export const setManageMembersSearch = (search: string) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { search },
        type: SET_MANAGE_MEMBERS_SEARCH,
    });
};

export const SET_MANAGE_MEMBERS_SHOW_ONLY_INACTIVE = 'SET_MANAGE_MEMBERS_SHOW_ONLY_INACTIVE';
export const setManageMembersShowOnlyInactive = (showOnlyInactive: boolean) => (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch({
        data: { showOnlyInactive },
        type: SET_MANAGE_MEMBERS_SHOW_ONLY_INACTIVE,
    });
};

export const SET_MANAGE_MEMBERS_PAGINATION_PARAMS = 'SET_MANAGE_MEMBERS_PAGINATION_PARAMS';
export const setManageMembersPaginationParams = (
    state: TableState<IUserTermDetailManageMemberDto>
) => (dispatch: AppStoreThunkDispatch) => {
    const { coverageLevelFilters, userTermDetailStatusFilters } = transformFiltersToEnumArray(
        state.filters
    );
    const orderings = transformSortByToOrderingArray<IUserTermDetailManageMemberDto>(
        state.sortBy ?? []
    );
    dispatch({
        data: {
            coverageLevelFilters,
            orderings,
            userTermDetailStatusFilters,
            page: state.pageIndex,
            pageSize: state.pageSize,
        },
        type: SET_MANAGE_MEMBERS_PAGINATION_PARAMS,
    });
};

export const CLEAR_MANAGE_MEMBERS_PAGINATION_PARAMS = 'CLEAR_MANAGE_MEMBERS_PAGINATION_PARAMS';
export const clearManageMembersPaginationParams = () =>
    dispatchWrapper(CLEAR_MANAGE_MEMBERS_PAGINATION_PARAMS);
