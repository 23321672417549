import commonAction from 'actions/commonAction';
import { Drug, IDrug } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const SET_DRUGS_FOR_USER_ACTION = 'SET_DRUGS_FOR_USER';

export const setDrugsForUser = (userId: string | undefined, drugs: IDrug[]) =>
    commonAction(
        async () =>
            new UsersApi().setDrugsForUser(
                userId,
                drugs.map((x) => new Drug(x))
            ),
        SET_DRUGS_FOR_USER_ACTION,
        {
            additionalDispatchData: { entityId: userId },
        }
    );
