import commonAction from 'actions/commonAction';
import SearchApi from 'api/generated/SearchApi';

export const GLOBAL_SEARCH_ACTION = 'GLOBAL_SEARCH';

export const globalSearch = (query: string, includeArchived: boolean) =>
    commonAction(
        async () => new SearchApi().globalSearch(query, includeArchived),
        GLOBAL_SEARCH_ACTION
    );
