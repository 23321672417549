import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION = 'GET_SINGLE_FILER_STANDARD_DEDUCTION';

export const getSingleFilerStandardDeduction = (year: number) =>
    commonAction(
        async () => new UsersApi().getSingleFilerStandardDeduction(year),
        GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION
    );
