import commonAction from 'actions/commonAction';
import { getUserProfile } from 'actions/user/getUserProfile';
import { ISelectedPlan, SelectedPlan } from 'api/generated/models';
import SelectedPlansApi from 'api/generated/SelectedPlansApi';

export const ADD_SELECTED_PLAN_ACTION = 'ADD_SELECTED_PLAN';

export const addSelectedPlan = (selectedPlan: ISelectedPlan, isCurrent: boolean) => {
    const planToSave = new SelectedPlan(selectedPlan);
    return commonAction(
        async () => new SelectedPlansApi().add(planToSave),
        ADD_SELECTED_PLAN_ACTION,
        {
            additionalDispatchData: {
                params: { planId: selectedPlan.planId },
            },
            successHook: ({ dispatch }) => {
                dispatch(getUserProfile(selectedPlan.userId, isCurrent));
            },
            toastSuccessMessage: 'Successfully selected a plan!',
        }
    );
};
