import commonAction from 'actions/commonAction';
import { Note } from 'api/generated/models';
import NotesApi from 'api/generated/NotesApi';

export const EDIT_USER_NOTE_ACTION = 'EDIT_USER_NOTE';

export const editUserNote = (note: Note) =>
    commonAction(async () => new NotesApi().editUserNote(note), EDIT_USER_NOTE_ACTION, {
        toastErrorMessage: 'Failed to edit note',
        toastSuccessMessage: 'Successfully edited note',
    });
