import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const DELETE_PATHWAY_ACTION = 'DELETE_PATHWAY';

export const deletePathway = (pathwayBlueprintId: string, pathwayId: string) =>
    commonAction(
        async () => new PathwayBlueprintApi().deletePathway(pathwayBlueprintId, pathwayId),
        DELETE_PATHWAY_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(pathwayBlueprintId));
            },
            toastErrorMessage: (response) =>
                `Unable to delete pathway. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully deleted pathway',
        }
    );
