import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const LIST_DRUGS_FOR_HOUSEHOLD_ACTION = 'LIST_DRUGS_FOR_HOUSEHOLD';

export const listDrugsForHousehold = (userId: string | undefined, year: number | undefined) =>
    commonAction(
        async () => new UsersApi().listDrugsForHousehold(userId, year),
        LIST_DRUGS_FOR_HOUSEHOLD_ACTION
    );
