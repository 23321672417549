import commonAction from 'actions/commonAction';
import {
    Address,
    IUserProfile,
    MemberQuoteInfo,
    User,
    UserProfile,
    YearlyUserInfoDto,
} from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { PEOPLE_PROFILE_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';

export const EDIT_USER_ACTION = 'EDIT_USER';

export const editUser = (
    { user, address, memberQuoteInfo, yearlyUserInfo }: IUserProfile,
    isTeamManagementPage: boolean | undefined,
    year?: number
) =>
    commonAction(
        async () =>
            new UsersApi().edit(
                year,
                new UserProfile({
                    address: new Address(address),
                    memberQuoteInfo: new MemberQuoteInfo(memberQuoteInfo),
                    user: new User(user),
                    yearlyUserInfo: new YearlyUserInfoDto(yearlyUserInfo),
                } as IUserProfile)
            ),
        EDIT_USER_ACTION,
        {
            additionalDispatchData: { isCurrent: true },
            successHook: ({ dispatch, response }) => {
                const { teamId, userId } = response.data.user ?? {};
                const profilePath = isTeamManagementPage
                    ? generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })
                    : generatePath(PEOPLE_PROFILE_PATH, { userId });

                dispatch(push(profilePath));
            },
            toastErrorMessage: 'Unable to update user profile',
            toastSuccessMessage: 'User has been modified',
        }
    );
