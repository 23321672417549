import { renameRhFile } from 'actions/rhFile/renameRhFile';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const RENAME_TEAM_BENEFIT_RH_FILE_ACTION = 'RENAME_TEAM_BENEFIT_RH_FILE';
export const renameTeamBenefitRhFile = (teamBenefitId: string | undefined, rhFile: IRhFile) =>
    renameRhFile(
        RENAME_TEAM_BENEFIT_RH_FILE_ACTION,
        async (newRhFile) => new RhFilesApi().renameTeamBenefitRhFile(teamBenefitId, newRhFile),
        rhFile
    );
