import commonAction from 'actions/commonAction';
import { SearchCommand } from 'api/generated/models';
import ProviderSearchApi from 'api/generated/ProviderSearchApi';

export const SEARCH_IDEON_PROVIDERS_WITH_TOKEN_ACTION = 'SEARCH_IDEON_PROVIDERS_WITH_TOKEN';

export const searchIdeonProvidersWithToken = (
    searchTerm: string,
    zipCode: string,
    planYear: number | undefined,
    token: string
) =>
    commonAction(
        async () =>
            new ProviderSearchApi().searchWithToken(
                new SearchCommand({
                    searchTerm,
                    token,
                    zipCode,
                    planYear: planYear as number,
                })
            ),
        SEARCH_IDEON_PROVIDERS_WITH_TOKEN_ACTION
    );
