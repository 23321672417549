import commonAction from 'actions/commonAction';
import SubmittedExpensesApi from 'api/generated/SubmittedExpensesApi';

export const CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION =
    'CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT';

export const calculateSubmittedExpenseReimbursementAmount = (
    globalId: string,
    expenseAmount: number,
    amountEligibleForReimbursement: number,
    reimburseToHsa: boolean
) =>
    commonAction(
        async () =>
            new SubmittedExpensesApi().calculateReimbursement(
                globalId,
                expenseAmount,
                amountEligibleForReimbursement,
                reimburseToHsa
            ),
        CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION,
        {
            toastErrorMessage(response) {
                if (response.error.response) {
                    return `Failed to calculate Reimbursement to Wages because: ${response.error.response.data}`;
                }
                return undefined;
            },
        }
    );
