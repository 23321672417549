import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const ASSIGN_USERS_ACTION = 'ASSIGN_USERS';

export const assignUsers = (
    blueprintId: string,
    pathwayId: string | undefined,
    userIds: string[]
) =>
    commonAction(
        async () => new PathwayBlueprintApi().assignUsers(blueprintId, pathwayId, userIds),
        ASSIGN_USERS_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(blueprintId));
            },
            toastErrorMessage: (response) =>
                `Unable to assign users to pathway. ${response.error.response?.data}`,
        }
    );
