import commonAction from 'actions/commonAction';
import MedicaidApi from 'api/generated/MedicaidApi';
import { IMedicaidRenewalDetails } from 'api/generated/models';
import { objectToPatchOperationsArray } from 'api/utilities';

export const CREATE_OR_PATCH_MEDICAID_RENEWAL_DETAILS_ACTION =
    'CREATE_OR_PATCH_MEDICAID_RENEWAL_DETAILS';

export const createOrPatchMedicaidRenewalDetails = (
    userId: string,
    year: number,
    renewalDetails: Partial<IMedicaidRenewalDetails>
) => {
    const renewalPatch = objectToPatchOperationsArray(renewalDetails);
    return commonAction(
        async () => new MedicaidApi().createOrPatchRenewalDetails(userId, year, renewalPatch),
        CREATE_OR_PATCH_MEDICAID_RENEWAL_DETAILS_ACTION,
        {
            toastErrorMessage: 'Unable to update details.',
            toastSuccessMessage: 'Details updated successfully',
        }
    );
};
