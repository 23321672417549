import commonAction from 'actions/commonAction';
import { IProvider, Provider } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER_ACTION = 'SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER';

export const setProvidersForHouseholdMember = (
    householdMemberId: string,
    year: number,
    providers: IProvider[]
) =>
    commonAction(
        async () =>
            new UsersApi().setProvidersForHouseholdMember(
                householdMemberId,
                year,
                providers.map((x) => new Provider(x))
            ),
        SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER_ACTION,
        {
            additionalDispatchData: { entityId: householdMemberId },
        }
    );
