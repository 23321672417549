import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const DELETE_PATHWAY_BLUEPRINT_ACTION = 'DELETE_PATHWAY_BLUEPRINT_ACTION';

export const deletePathwayBlueprint = (pathwayBlueprintId: string) =>
    commonAction(
        async () => new PathwayBlueprintApi().deletePathwayBlueprint(pathwayBlueprintId),
        DELETE_PATHWAY_BLUEPRINT_ACTION,
        {
            toastErrorMessage: (response) =>
                `Unable to delete pathway blueprint. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully deleted pathway blueprint',
        }
    );
