import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const COMPLETE_ENROLLMENT_ACTION = 'COMPLETE_ENROLLMENT';

export const completeEnrollment = (
    userId: string | undefined,
    year: number | undefined,
    shouldSendConfirmationEmail: boolean
) =>
    commonAction(
        async () => new UsersApi().completeEnrollment(userId, year, shouldSendConfirmationEmail),
        COMPLETE_ENROLLMENT_ACTION,
        {
            toastErrorMessage: 'Unable to complete enrollment',
            toastSuccessMessage: 'Member enrollment complete',
        }
    );
