import commonAction from 'actions/commonAction';
import { ISelectedPlan } from 'api/generated/models';
import SelectedPlansApi from 'api/generated/SelectedPlansApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_SELECTED_PLAN_ACTION = 'PATCH_SELECTED_PLAN';

export const patchSelectedPlans = (
    selectedPlanId: string | undefined,
    selectedPlan: Partial<ISelectedPlan>
) => {
    const selectedPlanPatch = objectToPatchOperationsArray(selectedPlan);
    return commonAction(
        async () => new SelectedPlansApi().patch(selectedPlanId, selectedPlanPatch),
        PATCH_SELECTED_PLAN_ACTION,
        {
            toastErrorMessage: (response) =>
                `Unable to edit plan. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully edited plan',
        }
    );
};
