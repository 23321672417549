import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const GET_LATEST_YEAR_FOR_RATES_ACTION = 'GET_LATEST_YEAR_FOR_RATES';

export const getLatestYearForRates = () =>
    commonAction(
        async () => new MarketplaceApi().getLatestYearForRates(),
        GET_LATEST_YEAR_FOR_RATES_ACTION
    );
