import commonAction from 'actions/commonAction';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const TOGGLE_TEAM_RH_FILE_ARCHIVE_ACTION = 'TOGGLE_TEAM_RH_FILE_ARCHIVE';

export const toggleTeamRhFileArchived = ({ id, isArchived }: IRhFile) =>
    commonAction(
        async () => new RhFilesApi().toggleTeamRhFileArchived(id),
        TOGGLE_TEAM_RH_FILE_ARCHIVE_ACTION,
        {
            toastErrorMessage: `Failed to ${isArchived ? 'un-archive' : 'archive'} file`,
            toastSuccessMessage: `Successfully ${isArchived ? 'un-archived' : 'archived'} file`,
        }
    );
