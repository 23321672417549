import commonAction from 'actions/commonAction';
import { IUserBudget, UserBudget } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const EDIT_USER_BUDGET_ACTION = 'EDIT_USER_BUDGET';

export const addOrEditUserBudget = (isNetAmount: boolean, userBudget: IUserBudget) =>
    commonAction(
        async () => new UsersApi().addOrEditBudget(isNetAmount, new UserBudget(userBudget)),
        EDIT_USER_BUDGET_ACTION,
        {
            toastSuccessMessage: 'Successfully edited budget',
        }
    );
