import commonAction from 'actions/commonAction';
import { listPagedTeamProfiles } from 'actions/team/listPagedTeamProfiles';
import TeamsApi from 'api/generated/TeamsApi';

export const ARCHIVE_TEAM_ACTION = 'ARCHIVE_TEAM';

export const archiveTeam = (teamId: string | undefined, archiveState: boolean) =>
    commonAction(
        async () => new TeamsApi().archiveTeam(teamId, archiveState),
        ARCHIVE_TEAM_ACTION,
        {
            successHook: ({ dispatch, getState }) => {
                dispatch(listPagedTeamProfiles(getState().teamsState));
            },
            toastErrorMessage: 'Unable to archive team',
            toastSuccessMessage: 'Team has been archived',
        }
    );
