import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const SET_CUSTOM_PROJECTED_WAGE_UP_ACTION = 'SET_CUSTOM_PROJECTED_WAGE_UP';

export const setCustomProjectedWageUp = (
    pathwayUserId: string,
    customProjectedWageUp: number | undefined,
    customProjectedWageUpNote: string | undefined
) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().setCustomProjectedWageUp(
                pathwayUserId,
                customProjectedWageUp,
                customProjectedWageUpNote
            ),
        SET_CUSTOM_PROJECTED_WAGE_UP_ACTION,
        {
            toastErrorMessage: 'Failed to update Projected Wage+',
            toastSuccessMessage: 'Projected Wage+ successfully updated',
        }
    );
