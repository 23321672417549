import commonAction from 'actions/commonAction';
import CustomQuestionsApi from 'api/generated/CustomQuestionsApi';
import { CustomQuestion, ICustomQuestion } from 'api/generated/models';

export const ADD_CUSTOM_QUESTION_ACTION = 'ADD_CUSTOM_QUESTION';

export const addCustomQuestion = (question: ICustomQuestion) =>
    commonAction(
        async () => new CustomQuestionsApi().addCustomQuestion(new CustomQuestion(question)),
        ADD_CUSTOM_QUESTION_ACTION,
        {
            toastErrorMessage: 'Unable to add question',
            toastSuccessMessage: 'Successfully added question',
        }
    );
