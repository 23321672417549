import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_HOUSEHOLD_MEMBERS_ACTION = 'GET_HOUSEHOLD_MEMBERS';

export const getHouseholdMembers = (
    userId: string | undefined,
    year?: number,
    includeAllHouseholdMembers = false
) =>
    commonAction(
        async () => new UsersApi().listHouseholdMembers(userId, year, includeAllHouseholdMembers),
        GET_HOUSEHOLD_MEMBERS_ACTION
    );
