import commonAction from 'actions/commonAction';
import { SurveyAuthentication } from 'api/generated/models';
import SurveyApi from 'api/generated/SurveyApi';
import { push } from 'connected-react-router';
import { LOGIN_PATH } from 'routers/routes';

export const GET_SURVEY_USER_PROFILE_ACTION = 'GET_SURVEY_USER_PROFILE';

export const getSurveyUserProfile = (token: string, email: string) =>
    commonAction(
        async () => new SurveyApi().authenticate(new SurveyAuthentication({ email, token })),
        GET_SURVEY_USER_PROFILE_ACTION,
        {
            additionalDispatchData: { isCurrent: true },
            nonSuccessHook: ({ dispatch }) => dispatch(push(LOGIN_PATH)),
            toastErrorMessage: 'Failed to load survey data. Please contact your administrator',
        }
    );
