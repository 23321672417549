import commonAction from 'actions/commonAction';
import { IPathwayDecisionDto, PathwayDecisionDto } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const APPLY_PATHWAY_DECISION_ACTION = 'APPLY_PATHWAY_DECISION';

export const applyPathwayDecision = (decision: IPathwayDecisionDto) =>
    commonAction(
        async () =>
            new PathwayBlueprintApi().applyPathwayDecision(new PathwayDecisionDto(decision)),
        APPLY_PATHWAY_DECISION_ACTION,
        { additionalDispatchData: { isCurrent: true } }
    );
