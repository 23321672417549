import commonAction from 'actions/commonAction';
import SurveyApi from 'api/generated/SurveyApi';

export const GET_SURVEY_YEAR_FOR_TEAM_ACTION = 'GET_SURVEY_YEAR_FOR_TEAM';

export const getSurveyYearForTeam = (teamId: string) =>
    commonAction(
        async () => new SurveyApi().getSurveyYearForTeam(teamId),
        GET_SURVEY_YEAR_FOR_TEAM_ACTION
    );
