import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import IchraClassApi from 'api/generated/IchraClassApi';

export const DELETE_ICHRA_CLASS_ACTION = 'DELETE_ICHRA_CLASS';

export const deleteIchraClass = (ichraClassId: string, pathwayBlueprintId: string) =>
    commonAction(
        async () => new IchraClassApi().deleteIchraClass(ichraClassId, pathwayBlueprintId),
        DELETE_ICHRA_CLASS_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(pathwayBlueprintId));
            },
            successHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(pathwayBlueprintId));
            },
            toastErrorMessage: (response) =>
                `Unable to delete ICHRA Class. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully deleted ICHRA Class',
        }
    );
