import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const GET_CURRENT_USER_BUDGET_ACTION = 'GET_CURRENT_USER_BUDGET';

export const getCurrentUserBudget = (teamId: string, userId: string) =>
    commonAction(
        async () => new UsersApi().getCurrentBudget(teamId, userId),
        GET_CURRENT_USER_BUDGET_ACTION
    );
