import commonAction from 'actions/commonAction';
import { Address, IAddress, ITeam, Team, TeamProfile } from 'api/generated/models';
import TeamsApi from 'api/generated/TeamsApi';
import { push } from 'connected-react-router';

export const EDIT_TEAM_ACTION = 'EDIT_TEAM';

export const editTeam = (
    teamId: string,
    name: string,
    isCustomer: boolean,
    isPartner: boolean,
    isAdvisor: boolean,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    county: string,
    countyFips: string,
    fullTimeEmployeeCount?: number,
    partTimeEmployeeCount?: number,
    redirectUrl?: string
) => {
    const teamProfile = new TeamProfile({
        address: new Address({
            addressLine1,
            addressLine2,
            city,
            county,
            countyFips,
            state,
            zip,
        } as IAddress),
        team: new Team({
            fullTimeEmployeeCount,
            isAdvisor,
            isCustomer,
            isPartner,
            name,
            partTimeEmployeeCount,
            teamId,
        } as ITeam),
    });
    return commonAction(async () => new TeamsApi().edit(teamProfile), EDIT_TEAM_ACTION, {
        successHook: ({ dispatch }) => {
            if (redirectUrl !== undefined) {
                dispatch(push(redirectUrl));
            }
        },
        toastErrorMessage: 'Unable to update team info',
    });
};
