import commonAction from 'actions/commonAction';
import { IRhFile } from 'api/generated/models';
import RhFilesApi from 'api/generated/RhFilesApi';

export const TOGGLE_USER_RH_FILE_ARCHIVE_ACTION = 'TOGGLE_USER_RH_FILE_ARCHIVE';

export const toggleUserRhFileArchived = ({ id, isArchived }: IRhFile) =>
    commonAction(
        async () => new RhFilesApi().toggleUserRhFileArchived(id),
        TOGGLE_USER_RH_FILE_ARCHIVE_ACTION,
        {
            toastErrorMessage: `Failed to ${isArchived ? 'un-archive' : 'archive'} file`,
            toastSuccessMessage: `Successfully ${isArchived ? 'un-archived' : 'archived'} file`,
        }
    );
